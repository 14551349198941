import  "./ServicesPage.css";
import MyComponent99 from '../widgets/services/Services';
import MyComponent55 from '../widgets/gridview/grid';
import MyComponent from '../footer/page2';
import AppBar from "../appbar/AppBar";
import { Helmet } from 'react-helmet';

function ServicesPage() {
  return (
    <>
          <Helmet>
      <title>Sahara Services</title>
  <meta name="keywords" content="Creative Agency, logo, website development" />
  <meta name="description" content="Elevate Your Brand with Sahara Sculptors - Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta name="keywords" content="Sahara Sculptors, branding, CRM solutions, digital marketing, 360-degree branding"/>
  <meta name="author" content="Sahara Sculptors"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <meta property="og:title" content="Sahara Sculptors - Elevate Your Brand"/>
  <meta property="og:description" content="Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta property="og:image" content="URL to your image"/>
  {/* Add other meta tags as needed */}
</Helmet>
    <AppBar />
    <div className="content1">
      <div className="centered-column">


    <div className="sahara-community3">
    Our Services
          </div>  
    <div className="our-service">At SaharaSculptors, we offer a comprehensive suite of services designed to <br/>meet the diverse branding needs of our clients</div>
      <MyComponent55/>
   <MyComponent99/>
   <MyComponent />
      </div>
    </div>
    </>
  );
  }

export default ServicesPage
