import ServiceInfo from "../serviceinfo/containerrowchild";
import ContainerRow2 from "../container2/Homepage2";
import friday from "../assets/video/vedeos1.mp4";
import RenderColumn from "../reusables/rendercolumn";
import Headings from "../reusables/heading";
import Subheading from "../reusables/subheading";
import picture from "../assets/images/3670.png"
function ContainerRow3() {
  return (
    <div className="bxexd">
      <div className="top1">
        <Headings
        className={"left-word"}
          heading1="Elevate Your Brand"
          heading2="with Sahara"
          heading3="Sculptors"
        />
        <Subheading
        className={"right-word2"}
          subheading1="At Sahara Sculptors, we redefine excellence in 360-Degree Branding. From"
          subheading2="captivating visuals to seamless online experiences and expert social media."
          subheading3="an unforgettable force—because with Sahara Sculptors, we're not just good "
          subheading4="we're legendary."
        />
      </div>
      <ServiceInfo
      width="90%"
      height="90%"
       picture={picture}
       description="phones"
    title={<>360 Degree <br/> Branding</>}des= {<>At Sahara Sculptors, we redefine excellence in 360-Degree Branding. 
  From <br/>captivating visuals to seamless online experiences and expert social media,<br/>
   we don't just create brands; we craft legends.shape your brand into <br/>an 
   unforgettable force—because with Sahara Sculptors, we're not just good<br/>we're legendary.</>}
                /> 
      <div className="lastnumber">
        <div className="lastnumber-kid">
          {" "}
          {RenderColumn(
            "Visual Masterpieces",
            "  Craft attention-grabbing brand identities.  ",
            "Ensure consistent and appealing",
            " brand materials. ",
          )}
        </div>
        <div className="lastnumber-kid">
          {" "}
          {RenderColumn(
            "Digital Presence Mastery",
            "Craft compelling websites, tell impactful stories,",
            "boost online presence, and deliver",
            "seamless user experiences."
          )}
        </div>
        <div className="lastnumber-kid">
          {" "}
          {RenderColumn(
            "Impactful Marketing Collateral",
            "Craft high-impact print and digital collateral",
            "for a lasting impression across traditional ",
            "and digital platforms."
          )}
        </div>
      </div>
      {ContainerRow2(
  <div className="billboard">
    <video
      autoPlay
      playsInline
      muted
      loop
      style={{ width: '100%', height: '100%', objectFit: "contain" }}
    >
      <source src={friday} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>

   
)}
{/* 
<div className="billboard7">
      <img src={purples2} alt="purple.png" className="billboard" />
 </div>   */}
    </div>
  );
}

export default ContainerRow3;
