import React from "react";
import './services.css'

function MyComponent99() {
  return (
    <>
      <div className="container">
        <div className="content">
          <div className="title">More service?</div>
          <div className="title">Confused by your Budget?</div>
          <div className="title">Strugling to start a business?</div>
          <div className="description">
            You can tell us what you need and we can help! through our Whatsapp click to call/text us Now!!
          </div>
        </div>
        <div className="button-container">
        
          <div className="button"> <div onClick={handleChoose}>Call Us Now</div></div>
        </div>
      </div>
   
    </>
  );
}
const handleChoose = () => {
  const whatsappLink = generateWhatsAppLink();
  window.open(whatsappLink, '_blank');
};
function generateWhatsAppLink(packageInfo) {
  const message = `Hi, I'm interested in the  package. Can you provide more details?`;

  // Replace '123456789' with the actual phone number
  const phoneNumber = '0656604895';

  return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
}

export default MyComponent99;
