import AppBar from "../appbar/AppBar";
import ServiceInfo2 from "../widgets/serviceinfo/containerrow40";
import ContainerRow2 from '../widgets/container2/Homepage2';
import friday from "../assets/video/MAIN_1.mp4";
import RenderColumn from '../widgets/reusables/rendercolumn'
import ServiceInfo from '../widgets/serviceinfo/containerrowchild'
import picture3 from "../assets/images/house.png";
import picture2 from "../assets/images/moon.png";
import picture1 from "../assets/images/iPad Mini.png";
import MyComponent from "../footer/page2";
import { Helmet } from 'react-helmet';

function AboutPage() {
  return (
    <div>
      <Helmet>
      <title>About Sahara Sculptors</title>
  <meta name="keywords" content="Creative Agency, logo, website development" />
  <meta name="description" content="Elevate Your Brand with Sahara Sculptors - Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta name="keywords" content="Sahara Sculptors, branding, CRM solutions, digital marketing, 360-degree branding"/>
  <meta name="author" content="Sahara Sculptors"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <meta property="og:title" content="Sahara Sculptors - Elevate Your Brand"/>
  <meta property="og:description" content="Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta property="og:image" content="URL to your image"/>
</Helmet>
      <AppBar></AppBar>
    <div className="bxexd">
      <div className="top">
    
    <div className="about-us">
          Read More About Us
          </div>  
      </div>
      <ServiceInfo
         width="120%"
         height="120%"
           picture={picture2}
           description="phones"
  title={<>Mission & Vision</>}
  des={<>At Sahara Sculptors, our mission is to redefine excellence
   in 360-Degree Branding. From captivating visuals to seamless 
   online experiences and expert social media, we go beyond
   creating brandswe craft legends. Let us shape your 
   brand into an unforgettable force—because with 
   Sahara Sculptors, we're not just good
    we're legendary.</>}
/>

<ServiceInfo2
   width="120%"
   height="120%"
     picture={picture1}
     description="phones"
  title={<>Contract with Sahara</>}
  des={<>Join Sahara Sculptors and embark on a journey to redefine excellence in 
    360-Degree Branding. From captivating visuals to seamless online experiences 
    and expert social media, we empower companies to contract through us, integrating 
    their services and entire marketing department with our legendary profile. 
    Start your partnership with Sahara Sculptors .</>}
/>
<ServiceInfo
   width="140%"
   height="140%"
     picture={picture3}
     description="phones"
  title={<><br/>Our Community</>}
  des={<>SAt Sahara Sculptors, we are a forward-thinking branding agency
  <br/>dedicated to reshaping the way businesses approach   branding in
  today's dynamic marketplace. Our journey began 
   with a vision to empower <br/>businesses of all sizes by sculpting
    distinctive brand identities that transcend industry norms.

  Our mission is to empower businesses to thrive amidst change, 
  guided by the belief that every brand has a unique story to
  tell. We are committed to delivering results that go beyond 
  aesthetics, blending creativity and data-driven insights to 
   your brand flourish in an ever-evolving world.
  
  Our team comprises passionate professionals with diverse
   backgrounds, all united by the commitment to innovation
    and client success. We stand ready to partner with you,
     offering creative solutions that will help your brand
      adapt,   evolve, and ultimately achieve its full potential.
       Welcome to Sahara Sculptors, where we craft brand success stories, 
       one masterpiece at a time.</>}


                />
       <div className="lastnumber">
  <div className="lastnumber-kid">
    {" "}
    {RenderColumn(
      "Our Vision",
      "Crafting attention-grabbing brand identities.",
      "Ensuring consistency and appeal in all",
      "brand materials."
    )}
  </div>
  <div className="lastnumber-kid">
    {" "}
    {RenderColumn(
      "Our Mission",
      "Crafting compelling websites, telling impactful",
      "boosting online presence, and delivering",
      "seamless user experiences."
    )}
  </div>
  <div className="lastnumber-kid">
    {" "}
    {RenderColumn(
      "Our Values",
      "Crafting high-impact print and digital collateral",
      "for a lasting impression across traditional ",
      "and digital platforms."
    )}
  </div>
</div>

{ContainerRow2(
  <div className="billboard">
    <video
      autoPlay
      playsInline
      muted
      loop
      style={{ width: '100%', height: '100%', objectFit: "contain" }}
    >
      <source src={friday} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>

   
)};

      
    </div>
    <MyComponent/>
    </div>
    
  );
}

export default AboutPage;
