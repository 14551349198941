// CookieConsent.jsx
import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentComponent = () => {
  return (
    <CookieConsent
    
      location="bottom"
      buttonText="Accept"
      cookieName="myCookieConsent"
      style={{width: "70%", background: '#333', color: '#fff' }}
      buttonStyle={{ background: '#fff', color: '#333', fontSize: '14px' }}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};

export default CookieConsentComponent;
