import React, { useEffect, useRef } from 'react';


function ServiceInfo2(   {width,height, picture,description,title,des}) {
  const secondRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = secondRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        // Check if the element is in the viewport
        if (rect.top <= windowHeight * 0.75) {
          element.classList.add('animate');
        } else {
          element.classList.remove('animate');
        }
      }
    };

    // Attach the event listener to the scroll event
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <>
    <div className="second" ref={secondRef}>
      <div className="second-kid1">
      <div className="global900">
            <div>
              <b className="sahara-community">
             { title} 
              </b>
              <div className="welcome-to-sahara">
             
             {des}
             </div>
             
            </div>


            
          </div>
      </div>
      <div className="second-kid1">
   
      <div className="global">   <img className='yello-picture44' width={width} height={height} src={picture}alt={description} />
  </div>
         
        </div>
        </div>
        </>
  );
}

export default ServiceInfo2;
