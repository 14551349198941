import profile from "../assets/images/saharalogo.png";
import product1 from "../assets/images/product1.png";
import product2 from "../assets/images/product2.png";
import product3 from "../assets/images/product3.png";
import product4 from "../assets/images/product4.png";
import product5 from "../assets/images/product5.png";
import product6 from "../assets/images/product6.png";
import product7 from "../assets/images/product7.png";
import product8 from "../assets/images/product8.png";
import product9 from "../assets/images/product9.png";
import product10 from "../assets/images/product10.png";
import product11 from "../assets/images/product11.png";
import product12 from "../assets/images/product12.png";
import "./StorePage.css";
import Card from './card'
import React from "react";
import product13 from "../assets/images/bill3.png";
import product14 from "../assets/images/business.png";
import product15 from "../assets/images/product13.png";
import product16 from "../assets/images/moon.png";

function Photos() {



return(
<div class="container-text-center">


<div class="row">
  <div class="col">
    <div class="col-kid">
      <Card
            avatarUrl={profile}

      fullName="Sahara Sculptors"
        dataImage={product1}
        productName="Apparel Artistry"
        productdeschead="Our Apparel Artistry... "
        productDescription="Our Apparel Artistry service brings a touch of sophistication to your clothing line. With unique designs tailored to your brand identity, we ensure your apparel stands out in the crowded fashion landscape."
        
      />
    </div>
    <div class="col-kid">
      <Card
            avatarUrl={profile}
      fullName="Sahara Sculptors"
        dataImage={product2}
        productName="Packaging Panache"
        productdeschead="Make a lasting impression... "
        productDescription="Make a lasting impression with Packaging Panache. We specialize in creating packaging solutions that not only protect your products but also tell a compelling brand story. Our attention to detail ensures that every package becomes a work of art."
      />
    </div>
    <div class="col-kid">
      <Card
            avatarUrl={profile}
      fullName="Sahara Sculptors"
        dataImage={product3}
        productName="Virtual Presence Dynamics" 
        productdeschead="Redefine your virtual presence .."
    productDescription="Explore the limitless possibilities of Virtual Presence Dynamics. From interactive websites to immersive online experiences, we leverage cutting-edge technologies to elevate your brand's digital footprint"
      />
    </div>
    <div class="col-kid">
      <Card
            avatarUrl={profile}
      fullName="Sahara Sculptors"
        dataImage={product4}
        productName="Become a sahara contructor"
        productDescription="Join the league of Sahara Constructors and play a pivotal role in shaping digital landscapes. This program offers hands-on experience, mentorship, and the opportunity to contribute to innovative projects."
        productdeschead="description.."
      />
    </div>
  </div>





  <div class="col">
    <div class="col-kid">
      <Card
            avatarUrl={profile}
      fullName="Sahara Sculptors"
        dataImage={product5}
        productName="Photography "
        productDescription="Our Photography service captures moments with an artistic flair. Whether it's product photography, events, or brand storytelling, we bring images to life that resonate with your audience.with Emission Studios.. designs are coming soon"
        productdeschead="Our Photography service"
      />
    </div>

    <div class="col-kid">
      <Card
            avatarUrl={profile}
      fullName="Sahara Sculptors"
        dataImage={product6}
        productName="System Admin & Automations"
        productDescription="Streamline your operations with our System Admin & Automations service. We design and implement robust systems, ensuring seamless workflows and efficiency in your business processes."
        productdeschead="Streamline your operations..."
      />
    </div>
    <div class="col-kid">
      <Card
            avatarUrl={profile}
      fullName="Sahara Sculptors"
        dataImage={product7}
        productName="Video Production"
        productDescription="Tell your brand story through captivating visuals. Our Video Production service combines creativity and technical expertise to deliver videos that engage, inspire, and leave a lasting impression."
        productdeschead="ell your brand story.."
      />
    </div>
    <div class="col-kid">
      <Card
            avatarUrl={profile}
      fullName="Sahara Sculptors"
        dataImage={product8}
        productName="Strategy development"
        productDescription="Craft a roadmap for success with our Strategy Development service. We work closely with you to define goals, analyze market trends, and formulate strategies that position your brand for growth."
        productdeschead="Craft a roadmap ..."
      />
    </div>
  </div>
  <div class="col">
  <div class="col-kid">
      <Card
            avatarUrl={profile}
      fullName="Sahara Sculptors"rd
        dataImage={product9}
        productName="Social Media Content Production"
        productDescription="Elevate your social media presence with visually stunning content. Our Social Media Content Production service ensures that your brand stays relevant and resonates with your target audience."
        productdeschead="Elevate your social media .."
      />
    </div>  <div class="col-kid">
      <Card

avatarUrl={profile}
      fullName="Sahara Sculptors"dataImage={product10}
        productName="Black friday Unique Code"
        productDescription="Unlock the power of Black Friday with our Unique Code service. Stand out from the crowd with exclusive promotions and discounts tailored for the biggest shopping day of the year.(provide SAHARABLACK09 to your price tailor ...contact us !!"
        productdeschead="Unlock the power of Blac"
      />
    </div><div class="col-kid">
      <Card
       avatarUrl={profile}
       fullName="Sahara Sculptors"

        dataImage={product11}
        productName="UI UX Development"
        productDescription="Create seamless and user-friendly experiences with our UI UX Development service. We focus on designing interfaces that not only look appealing but also enhance user engagement."
        productdeschead="Create seamless and user-friendly..."
      />
    </div> <div class="col-kid">
      <Card
           avatarUrl={profile}
           fullName="Sahara Sculptors"
        dataImage={product12}
        productName="Virsual presence inhancement"
        productDescription="The Virtual Presence Enhancement Suite redefines online excellence. From website enhancements to immersive online experiences, we offer solutions that ensure your brand leaves a lasting digital impression."
        productdeschead="The Virtual Presence Enhancement.."
      />
    </div>
  </div>





  <div class="col">
  <div class="col-kid">
      <Card
 avatarUrl={profile}
 fullName="Sahara Sculptors"
        dataImage={product13}
        productName="Ditital/Physica billboard "
        productDescription="Amplify your brand visibility with Digital/Physical Billboard solutions. Whether in the digital realm or physical spaces, our billboards are designed to grab attention and convey your brand message effectively."
        productdeschead="Amplify your brand ..."
      />
    </div>  <div class="col-kid">
      <Card

avatarUrl={profile}
      fullName="Sahara Sculptors"dataImage={product14}
        productName="Company Profile Building"
        productDescription="Build a compelling narrative for your brand with our Company Profile Building service. We craft profiles that reflect your brand values, achievements, and aspirations, creating a powerful impression on stakeholders."
        productdeschead="Build a compelling narrative..."
      />
    </div><div class="">
      <Card
           avatarUrl={profile}
           fullName="Sahara Sculptors"
        dataImage={product15}
        productName="Sculpted backgrounds"
        productDescription=" Immerse your audience in a world of creativity with Sculpted Backgrounds. Our designs transform ordinary spaces into visually appealing backdrops that enhance the overall brand experience."
        productdeschead="Immerse your audience ..."
      />
    </div> <div class="col-kid">
      <Card
           avatarUrl={profile}
           fullName="Sahara Sculptors"
        dataImage={product16}
        productName="Embed 3d in your existing web"
        productDescription="Our service allows you to embed 3D elements into your existing website, adding a layer of interactivity and engagement. Enhance user experiences with cutting-edge technology."
        productdeschead="Our service allows you to ..."
      />
    </div>
  </div>
</div>
</div>

);
}
export default Photos