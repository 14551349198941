import React from 'react';
import './port.css'; // Import CSS file for styling
import AppBar from '../components/appbar/AppBar'

const PortfolioPage = () => {
  // Placeholder image URLs
  const imageUrls = [
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
    "https://via.placeholder.com/300",
  ];

  return (
    <div className="portfolio-page">

      <AppBar/>
      <div className="header">
        <h1>Discover Our Work</h1>
        <p>Explore some of our recent projects below</p>
      </div>
      <div className="portfolio-gallery">
        {imageUrls.map((url, index) => (
          <div key={index} className="portfolio-item">
            <img src={url} alt={`Portfolio Item ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortfolioPage;
