import React from "react";
import '../../tabs/shortcodes.css'; // Import the CSS for styling
import Premium from '../../tabs/premium';
import Comprehensive from '../../tabs/comprehensive';
import BudgetFriendly from '../../tabs/budgetfriendly';
function Pricing3() {
  return (
    <section className="pricing-section-three">
      <div className="tf-container st3">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-res">
              <table className="pricing-table">
                <tbody>

<Premium

tittle='Cloud Based CRM'

/>
<Premium

tittle='Contact Management system'

/>
<Premium

tittle='User Training'

/>
<Premium

tittle='Standard data encryption'

/>
<Comprehensive

title='Sales pipeline management'

/>
<Comprehensive

title='Onboarding Sessions,user Guides'

/><Comprehensive

title='Custom DashBoard'

/>
<Comprehensive

title='News letter Campaigns'

/>
<BudgetFriendly

title='Customized CRM Solution'

/><BudgetFriendly

title='Tailored modules,API integrations'

/>
<BudgetFriendly

title='ERP Systems & third Party'

/>
<BudgetFriendly

title='Enhanced Security Protocols'

/>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing3;
