import React, { useEffect } from 'react';

const Subheading = ({ subheading1, subheading2, subheading3, subheading4, className }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    }, { threshold: 0.5 });

    const subheadingElement = document.querySelector(`.${className}`);

    if (subheadingElement) {
      observer.observe(subheadingElement);
    }

    return () => {
      observer.disconnect();
    };
  }, [className]); // Run the effect once on mount and whenever className changes

  return (
    <div className={className}>
      <div className="centered-text2">
        {subheading1}
     
        {subheading2}
     
        {subheading3}
       
        {subheading4}
     
      </div>
    </div>
  );
};

export default Subheading;
