import "./HomePage2.css";

function ContainerRow2(burnner) {
  return (
    <>
      <div className="bxex4">
     {burnner}
      </div>

    </>
  );

}
export default ContainerRow2;
