import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import emailjs library
import './quiz.css'; // Import your CSS file

function BusinessApplicationForm() {
  const [formData, setFormData] = useState({
    businessName: '',
    registrationNumber: '',
    ownerFirstName: '',
    ownerLastName: '',
    phoneNumber: '',
    email: '',
    website: '',
    businessAddress: '',
    industry: '',
    numberOfEmployees: '',
    businessDescription: '',
    whyNeedWebsite: '',
    selectedSubscription: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form validation
    for (let key in formData) {
      if (!formData[key] && key !== 'website') {
        alert("Please fill out all fields");
        return;
      }
    }

    setIsLoading(true); // Set loading state to true

    try {
      const emailResult = await emailjs.send('service_5h9oi3h', 'template_3nnz6ge', formData, '4cCT6qPasGFQbWzCs');
      alert('Thank you for applying for the SAHARA Local to Global Programme. Our team will be in touch with you!');
      // Reset form fields
      setFormData({
        businessName: '',
        registrationNumber: '',
        ownerFirstName: '',
        ownerLastName: '',
        phoneNumber: '',
        email: '',
        website: '',
        businessAddress: '',
        industry: '',
        numberOfEmployees: '',
        businessDescription: '',
        whyNeedWebsite: '',
        selectedSubscription: ''
      });
      setIsModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to submit the application. Please try again later.');
    } finally {
      setIsLoading(false); // Set loading state back to false
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSubscriptionSelect = (subscription) => {
    setFormData({ ...formData, selectedSubscription: subscription });
    openModal();
  };

  return (
    <div className="giveaway-info">
      <div className="exclusive-giveaways-container">
       
        <p>Choose a subscription plan to apply for:</p>
{/* 
        <h3>Website Subscription Plans</h3>
        <div className="subscription-plans">
          <div className="plan" onClick={() => handleSubscriptionSelect('Basic Website Plan - R200/month')}>
            <h3>Basic Plan</h3>
            <p>R200/month</p>
            <ul>
              <li>Monthly website maintenance</li>
              <li>Basic security updates</li>
              <li>Monthly backup</li>
              <li>Google Business setup</li>
              <li>Free SEO</li>
              <li>Free 24/7 support</li>
              <li>Hosting</li>
              <li>50 .co.za email addresses</li>
            </ul>
            <button className="select-button">Select</button>
          </div>
          <div className="plan" onClick={() => handleSubscriptionSelect('Standard Website Plan - R300/month')}>
            <h3>Standard Plan</h3>
            <p>R300/month</p>
            <ul>
              <li>Everything in Basic Plan</li>
              <li>Bi-weekly updates</li>
              <li>Performance optimization</li>
            </ul>
            <button className="select-button">Select</button>
          </div>
          <div className="plan" onClick={() => handleSubscriptionSelect('Premium Website Plan - R500/month')}>
            <h3>Premium Plan</h3>
            <p>R500/month</p>
            <ul>
              <li>Everything in Standard Plan</li>
              <li>Weekly updates</li>
              <li>Priority support</li>
              <li>Minor content updates</li>
            </ul>
            <button className="select-button">Select</button>
          </div>
          <div className="plan" onClick={() => handleSubscriptionSelect('Business Website Plan - R700/month')}>
            <h3>Business Plan</h3>
            <p>R700/month</p>
            <ul>
              <li>Everything in Premium Plan</li>
              <li>Monthly analytics report</li>
              <li>Enhanced SEO optimization</li>
              <li>Content updates and blog posting (up to 2 posts/month)</li>
            </ul>
            <button className="select-button">Select</button>
          </div>
          <div className="plan" onClick={() => handleSubscriptionSelect('Enterprise Website Plan - R1000/month')}>
            <h3>Enterprise Plan</h3>
            <p>R1000/month</p>
            <ul>
              <li>Everything in Business Plan</li>
              <li>E-commerce support</li>
              <li>Advanced security features</li>
              <li>Unlimited content updates</li>
              <li>Custom development hours (up to 5 hours/month)</li>
            </ul>
            <button className="select-button">Select</button>
          </div>
        </div> */}

        <h3>Social Media Management Plans</h3>
        <div className="subscription-plans">
          <div className="plan" onClick={() => handleSubscriptionSelect('Basic Social Media Plan - R200/month')}>
            <h3>Basic Plan</h3>
            <p>R200/month</p>
            <ul>
              <li>10 posters per month</li>
              <li>Basic account management</li>
              <li>Monthly performance report</li>
            </ul>
            <button className="select-button">Select</button>
          </div>
          <div className="plan" onClick={() => handleSubscriptionSelect('Standard Social Media Plan - R300/month')}>
            <h3>Standard Plan</h3>
            <p>R300/month</p>
            <ul>
              <li>Everything in Basic Plan</li>
              <li>20 posters per month</li>
              <li>Bi-weekly performance report</li>
            </ul>
            <button className="select-button">Select</button>
          </div>
          <div className="plan" onClick={() => handleSubscriptionSelect('Premium Social Media Plan - R500/month')}>
            <h3>Premium Plan</h3>
            <p>R500/month</p>
            <ul>
              <li>Everything in Standard Plan</li>
              <li>30 posters per month</li>
              <li>Weekly performance report</li>
              <li>Priority support</li>
            </ul>
            <button className="select-button">Select</button>
          </div>
          <div className="plan" onClick={() => handleSubscriptionSelect('Business Social Media Plan - R700/month')}>
            <h3>Business Plan</h3>
            <p>R700/month</p>
            <ul>
              <li>Everything in Premium Plan</li>
              <li>40 posters per month</li>
              <li>Advanced account management</li>
              <li>Custom content strategy</li>
            </ul>
            <button className="select-button">Select</button>
          </div>
          <div className="plan" onClick={() => handleSubscriptionSelect('Enterprise Social Media Plan - R1000/month')}>
            <h3>Enterprise Plan</h3>
            <p>R1000/month</p>
            <ul>
              <li>Everything in Business Plan</li>
              <li>50 posters per month</li>
              <li>Dedicated account manager</li>
              <li>Custom campaign creation</li>
            </ul>
            <button className="select-button">Select</button>
          </div>
        </div>

        <div id="myModal" className="modal" style={{ display: isModalOpen ? 'block' : 'none' }}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2>Application Form</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input type="text" name="businessName" placeholder="Business Name" value={formData.businessName} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="registrationNumber" placeholder="Business Registration Number" value={formData.registrationNumber} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="ownerFirstName" placeholder="Owner's First Name" value={formData.ownerFirstName} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="ownerLastName" placeholder="Owner's Last Name" value={formData.ownerLastName} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <input type="tel" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <input type="url" name="website" placeholder="Existing Website (if any)" value={formData.website} onChange={handleChange} />
              </div>
              <div className="form-group">
                <input type="text" name="businessAddress" placeholder="Business Address" value={formData.businessAddress} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <input type="text" name="industry" placeholder="Industry" value={formData.industry} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <input type="number" name="numberOfEmployees" placeholder="Number of Employees" value={formData.numberOfEmployees} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <textarea name="businessDescription" placeholder="Brief Description of Your Business" value={formData.businessDescription} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <textarea name="whyNeedWebsite" placeholder="Why Does Your Business Need a Website?" value={formData.whyNeedWebsite} onChange={handleChange} required />
              </div>
              <button type="submit" disabled={isLoading}>Submit Application</button>
            </form>
            {isLoading && <p>Loading...</p>}
          </div>
        </div>
      </div>
      <ul className="social-list">
        <li>
          <a href="https://www.facebook.com/saharasculptors" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-square"></i> Facebook
          </a>
        </li>
        <li>
          <a href="https://twitter.com/saharasculptors" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter-square"></i> Twitter
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/saharasculptors" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram-square"></i> Instagram
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/sahara-sculptors" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i> LinkedIn
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCYcUZH2dl7NeUZSe5bIFfJQ" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube-square"></i> YouTube
          </a>
        </li>
        <li>
          <a href="https://www.pinterest.com/saharasculptors" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-pinterest-square"></i> Pinterest
          </a>
        </li>
      </ul>
    </div>
  );
}

export default BusinessApplicationForm;
