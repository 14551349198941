import ServiceInfo from "../serviceinfo/containerrowchild";
import ContainerRow2 from "../container2/Homepage2";
import renderColumn from "../reusables/rendercolumn";
import Headings from "../reusables/heading";
import Subheading from "../reusables/subheading";
import "./Containerrow3.css";
// import boardw from "../assets/images/bill3.png";
import picture from "../assets/images/iphones.png"
function ContainerRow9() {
  return (
    <div className="bxexd">
      <div className="top1">
        <Headings
          className={"left-word2"}
          heading1="Propelling Brands "
          heading2="to Popularity with "
          heading3="Sahara SEO"
        />
        <Subheading
          className={"right-word2"}
          subheading1=" Real-time Monitoring and Response  Algorithmic Optimization "
          subheading2="Engaging Website Design, Compelling Content, and Seamless User Experiences."
          subheading3="Elevate your online presence with expert Social Media Management."
          subheading4="High-impact Collateral for a cohesive brand experience."
        />
      </div>
      <ServiceInfo
       width="120%"
       height="120%"
         picture={picture}
         description="phones"
            
        title={
          <>
            Sahara SEO <br /> Solutions
          </>
        }
        des={
          <>
            {" "}
            At Sahara Sculptors, our SEO solutions go beyond mere optimization  <br />
            they arestrategic maneuvers to propel your digital presence to new  <br /> 
            heights.  With ourmeticulous keyword research, on-page optimization, <br /> 
            and robust backlink strategies <br /> 
             engine rankings organically We don't just target visibility we aim for .<br /> 
            making your website a beacon for your industry. Trust  Sculptors for SEO <br />
            solutions that redefine your online footprint.<br />
          </>
        }
      ></ServiceInfo>
      <div className="lastnumber">
        <div className="lastnumber-kid">
          {" "}
          {renderColumn("Strategic Keyword Research",
            "In-depth analysis of industry-specific keywords",
            "Identification of high-impact long-tail keywords",
            "Competitor keyword landscape evaluation",
          )}
        </div>
        <div className="lastnumber-kid">
          {" "}
          {renderColumn(
            "On-Page Optimization Excellence",
            "Precision in meta tags and title optimizations",
            "Content structure enhancement for search engine ",
            "Implementation of SEO-friendly URL structures",
          )}
        </div>
        <div className="lastnumber-kid">
          {" "}
          {renderColumn(
            "Robust Backlink Strategies",
            "Cultivation of high-quality, authoritative backlinks",
            " Strategic link-building campaigns",
            "Continuous backlink profile monitoring ",
          )}
        </div>
      </div>
      {ContainerRow2(
  //    <div className="billboard">
  //    <img src={boardw} alt="benoni.jpg" className="billboard-image" />
  //  </div>
      )}
      
    </div>
  );
}
export default ContainerRow9;
