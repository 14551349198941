import ServiceInfo from "../serviceinfo/containerrowchild";
import ContainerRow2 from '../container2/Homepage2'
import renderColumn from "../reusables/rendercolumn";
import Headings from "../reusables/heading";
import Subheading from "../reusables/subheading";
import "./Containerrow3.css";
import boardw from "../assets/images/bill3.png";
import picture from "../assets/images/iPad Mini.png"
function ContainerRow10() {
  return (
    <div className="bxexd">
      <div className="top1">
        <Headings
          className={"left-word2"}
          heading1="Empowering Ideas  "
          heading2="Through Next-Gen"
          heading3="Software  "
        />
        <Subheading
          className={"right-word2"}
          subheading1=" Ensure consistent, captivating Graphic Design across all materials."
          subheading2="Engaging Website Design, Compelling Content, and Seamless User  Experiences."
          subheading3="Elevate your online presence with expert Social Media Management."
          subheading4="High-impact Collateral for a cohesive brand experience."
        />
      </div>

      <ServiceInfo
       width="200%"
       height="160%"
         picture={picture}
         description="phones"
            
           
        title={
          <>
          Social Media<br /> Management
    
          </>
        }
        des={
          <>
            {" "}
            In the age of social connectivity, Sahara Sculptors<br /> brings your brand to life
             through expert social media management. We navigate the ever-changing <br />
             landscape of social platforms to amplify your online presence.From  strategy development  <br />
            to content curation, we ensure your brand <br />
             remains vibrant and engaging in the digital sphere.<br />
          </>
        }
      ></ServiceInfo>
      
     <div className="lastnumber">
  <div className="lastnumber-kid">
    {renderColumn(
      "Social Media Management",
      "Expertly manage your social media presence.",
      "Create engaging content and",
      "build a strong online community."
    )}
  </div>
  <div className="lastnumber-kid">
    {renderColumn(
      "Content Creation Strategy",
      "Develop a compelling content strategy.",
      "Craft impactful stories, posts,",
      "and multimedia content."
    )}
  </div>
  <div className="lastnumber-kid">
    {renderColumn(
      "Social Advertising Campaigns",
      "Run high-impact social media",
      "advertising campaigns to reach",
      "your target audience."
    )}
  </div>
</div>

      
      {ContainerRow2(
        <div className="billboard">
          {" "}
          <img src={boardw} alt="billborad.png" className="billboard" />
        </div>
      )}
      
    </div>
  );
}
export default ContainerRow10;
