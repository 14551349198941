import React, { useState } from "react";
import "./quiz.css"; // Import your CSS file
import AppBar from "../components/appbar/AppBar";
import { Helmet } from "react-helmet";
import BusinessApplicationForm from "./lapplication"; // Import the BusinessApplicationForm
import Beaner from "./beaner.jpg";

function Free() {
  const [] = useState(false);
  const [,] = useState(false);

  return (
    <>
      <Helmet>
        <title>Sahara Sculptors Affiliation</title>
        <meta
          name="keywords"
          content="Creative Agency, logo, website development"
        />
        <meta
          name="description"
          content="Elevate Your Brand with Sahara Sculptors - Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."
        />
        <meta
          name="keywords"
          content="Sahara Sculptors, branding, CRM solutions, digital marketing, 360-degree branding"
        />
        <meta name="author" content="Sahara Sculptors" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          property="og:title"
          content="Sahara Sculptors - Elevate Your Brand"
        />
        <meta
          property="og:description"
          content="Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."
        />
        <meta property="og:image" content="URL to your image" />
        {/* Add other meta tags as needed */}
      </Helmet>
      <AppBar />

      <div className="affiliation-container">
        <div className="affiliation-container">
          <h2>Business Application</h2>

          <div style={styles.container}>
            <img src={Beaner} alt="Beaner" style={styles.image} />
          </div>
          <p>
            We are excited to launch our Sahara local to global programme! We're
            Offering subscriptions to small businesses that are
            struggling to perfom online.
          </p>
         

          <p>To apply, simply follow these steps:</p>
          <ul>
            <li>
              📄 <strong>Submit Application:</strong> Complete the application
              form available on our website Depending on the
              subscription that is affordable to you.
            </li>
            <li>
              📞 <strong>Contact Details:</strong> Ensure your contact details
              are accurate so we can reach you if Your Business Qualify{" "}
            </li>
          </ul>
      

          <BusinessApplicationForm />
        </div>
      </div>
    </>
  );
}
const styles = {
  container: {},
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    border: "10px solid #fff", // White border around the image
  },
};
export default Free;
