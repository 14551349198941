import React from "react";
import '../../tabs/shortcodes.css'; // Import the CSS for styling
import Premium from '../../tabs/premium';
import Comprehensive from '../../tabs/comprehensive';
import BudgetFriendly from '../../tabs/budgetfriendly';
function Pricing3() {
  return (
    <section className="pricing-section-three">
      <div className="tf-container st3">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-res">
              <table className="pricing-table">
                <tbody>

<Premium

tittle='3-5 Responsive Pages'

/>
<Premium

tittle='Basic CMS Setup'

/>
<Premium

tittle='Basic onPage SEO Setup'

/>
<Premium

tittle='Email & Phone Support'

/>
<Comprehensive

title='5-20 Resposive Pages'

/>
<Comprehensive

title='CMS with Custom Capabilities'

/><Comprehensive

title='SEO optimization (all pages)'

/>
<Comprehensive

title='Premium Support 24/7'

/>
<BudgetFriendly

title='E-commerce Website'

/><BudgetFriendly

title='SEO (All pages/components)'

/>
<BudgetFriendly

title='Payment Gateway & Deliveries'

/>
<BudgetFriendly

title='Admin Development & Design'

/>


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing3;
