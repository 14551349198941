import React, { useEffect } from 'react';
const AnimatedHeadings = () => {
  useEffect(() => {
    const headings = document.querySelectorAll('.heading1, .heading2, .heading3');

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    }, { threshold: 0.5 });

    headings.forEach(heading => {
      observer.observe(heading);
    });

    return () => {
      observer.disconnect();
    };
  }, []); // Run the effect once on mount

  return (
    <>
      <div className="heading1">
        <p>Sculpting Brands</p>
      </div>
      <div className="heading2">
        <p>unlocking success</p>
      </div>
      <div className="heading3">
        <p>We are always happy to assist !!</p>
      </div>
    </>
  );
};

export default AnimatedHeadings;
