import React, { useEffect } from 'react';

function Headings({ heading1, heading2, heading3, className }) {
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    }, { threshold: 0.5 });

    const headingsElement = document.querySelector(`.${className}`);

    if (headingsElement) {
      observer.observe(headingsElement);
    }

    return () => {
      observer.disconnect();
    };
  }, [className]); // Run the effect once on mount and whenever className changes

  return (
    <div className={className}>
      <h1 className="centered-text1">
        {heading1} <br />
        {heading2}
        <br />
        {heading3}
      </h1>
    </div>
  );
}

export default Headings;
