import "./StorePage.css";
import AppBar from "../appbar/AppBar";
import { FaShareAlt ,FaThumbsUp,FaDownload } from 'react-icons/fa';
import MyComponent from '../footer/page2'
import {  FaTimesCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import ContainerRow2 from "../widgets/container2/Homepage2";
import friday from "../assets/video/vedeos1.mp4";
import React, { useState } from "react";
import { FaLock } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import SearchBar from './SearchBar'
import Photos from './photos'
function StorePage() {


  const [selectedNavItem, setSelectedNavItem] = useState("photos");

  const handleNavItemSelect = (navItem) => {
    setSelectedNavItem(navItem);
  };

  // const openRegistrationForm = () => {
  //   setShowRegistrationModal(true);
  // }

  // const closeRegistrationForm = () => {
  //   setShowRegistrationModal(false);
  // }
// Define Content Components
const PhotosContent = () => {
  return <div><Photos/></div>;
};

const VideosContent = () => {
  return <div>Videos Content Goes Here</div>;
};

const VectorsContent = () => {
  return <div>Vectors Content Goes Here</div>;
};
const Mockups = () => {
  return <div><Photos/></div>;
};



const Mobile = () => {
  return <div>Vectors Content Goes Here</div>;
};
const Website = () => {
  return <div><Photos/></div>;
};

const Templates = () => {
  return <div>Videos Content Goes Here</div>;
};

const PSD = () => {
  return <div>Vectors Content Goes Here</div>;
};
const Designer = () => {
  return <div>Vectors Content Goes Here</div>;
};
  return (
    <>
         <Helmet>
      <title>Discover Free Assets</title>
  <meta name="keywords" content="Creative Agency, logo, website development" />
  <meta name="description" content="Elevate Your Brand with Sahara Sculptors - Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta name="keywords" content="Sahara Sculptors, branding, download psd, download templates, 360-degree branding"/>
  <meta name="author" content="Sahara Sculptors"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <meta property="og:title" content="Sahara Sculptors - Elevate Your Brand"/>
  <meta property="og:description" content="Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta property="og:image" content="URL to your image"/>
  {/* Add other meta tags as needed */}
</Helmet>
      <AppBar />
      {/* {showRegistrationModal && (
        <div id="registrationModal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeRegistrationForm}><FaTimesCircle /></span>
            <form id="registrationForm">
              <label htmlFor="username">Username:</label>
              <input type="text" id="username" name="username" required /><br />
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required /><br />
              <label htmlFor="password">Password:</label>
              <input type="password" id="password" name="password" required /><br />
              <button type="submit">Register</button>
            </form>
          </div>
        </div>
      )} */}
      <div className="content1">
        <div className="centered-column">
          <div className="heading-gost">Developing and Designing Assets   <br></br>for your Buisness</div>
          <div className="heading-gos6"></div>
          {/* <div className="button-enquire">
            <div className="buttoncontact">
              <button onClick={openRegistrationForm}>Sign up</button>
            </div>
          </div> */}
          {/* <SearchBar/>
          <div className="productd-name">Designers and developers treasure trove of assets – photos, videos, vectors, PSDs, icons,website components,app components,templates and more.</div>
          <div className="ads9">
          <div class="onlinehead9 ">
            <div className="online9" onClick={() => handleNavItemSelect("photos")}>
              <p>Photos</p>
            </div>
            <div className="online9"  onClick={() => handleNavItemSelect("videos")}>
              <p>Videos</p>
            </div>
            <div className="online9" onClick={() => handleNavItemSelect("vectors")}>
              <p>Vectors</p>
            </div>
            
            
          </div>
          <div class="onlinehead91">
            <div className="online9" onClick={() => handleNavItemSelect("templates")}>
              <p>Templates</p>
            </div>
            <div className="online9" onClick={() => handleNavItemSelect("website")}>
              <p>Website Components</p>
            </div>
            <div className="online9" onClick={() => handleNavItemSelect("mobile")}>
              <p>Mobile Components</p>
            </div>
            <div className="online9" onClick={() => handleNavItemSelect("mockups")}>
              <p>Mockups</p>
            </div>
            <div className="online9"onClick={() => handleNavItemSelect("PSD")}>
              
              <p>PSD's</p>
            </div>
            <div className="online9" onClick={() => handleNavItemSelect("designer")}>
              <p>Designer Packages</p>
            </div>
          </div>
        </div> */}
        <div>


      {/* Render Content Based on Selected Navigation Item */}
      <div className="content">
        {selectedNavItem === "photos" && <PhotosContent />}
        {selectedNavItem === "videos" && <VideosContent />}
        {selectedNavItem === "vectors" && <VectorsContent />}
        {selectedNavItem === "template" && <Templates />}
        {selectedNavItem === "website" && <Website />}
        {selectedNavItem === "mobile" && <Mobile />}
        {selectedNavItem === "mockups" && <Mockups />}
        {selectedNavItem === "PSD" && <PSD />}
        {selectedNavItem === "designer" && <Designer />}
  
        {/* Add other content components as needed */}
      </div>
    </div>




          

        


          {ContainerRow2(

)};
          <MyComponent />
        </div>
      </div>
    </>
  );
}



export default StorePage;

