import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React from 'react';
import HomePage from './components/home/HomePage';
import AboutPage from './components/about/AboutPage';
import ServicesPage from './components/services/ServicesPage';
import CommunityPage from './components/pricing/CommunityPage';
import StorePage from './components/community/StorePage';
import { HelmetProvider } from 'react-helmet-async';
import Branding from './components/pricing/pricepages/360/branding';
import Social from './components/pricing/pricepages/Social/social';
import Website from './components/pricing/pricepages/Website/website';
import Mobile from './components/pricing/pricepages/Mobile/applications';
import Search from './components/pricing/pricepages/SEO/commercials';
import CRM from './components/pricing/pricepages/CRM/tailored';
import Profile from './components/appbar/profile'
import QuizApp from './Quiz/quiz';
import AffiliationScreen from './Quiz/quiz';
import PortfolioPage from './Quiz/port';
import Free from './Quiz/free';
const AppRouter = () => {
  return (
    <HelmetProvider>
    <Router>
      <div>
        <Routes>
          <Route path="/HomePage" element={<HomePage />} />
          <Route path="/AboutPage" element={<AboutPage />} />
          <Route path="/ServicesPage" element={<ServicesPage />} />
          <Route path="/CommunityPage" element={<CommunityPage />} />
          <Route path="/StorePage" element={<StorePage />} />
 <Route path="/Branding" element={<Branding />} />
 <Route path="/Social" element={<Social />} />
 <Route path="/Website" element={<Website />} />
 <Route path="/Mobile" element={<Mobile />} />
 <Route path="/Search" element={<Search />} />
 <Route path="/CRM" element={<CRM/>} />
 <Route path="/edit-profile" element={<Profile/>} />
 <Route path="/Affiliation" element={<AffiliationScreen/>} />
 <Route path="/Free" element={<Free/>} />
 <Route path="/port" element = {<PortfolioPage/>}/>
<Route path="/" element={<Navigate to="/HomePage" />} />
      
         
        </Routes>
      </div>
    </Router>
    </HelmetProvider>
  );
};

export default AppRouter;
