import "./render.css"


function RenderColumn(title, description1, description2, description3) {
    return (
      <div className="column">
        <div className="column-title">{title}</div>
        <div className="column-description">{description1}</div>
        <div className="column-description">{description2}</div>
        <div className="column-description">{description3}</div>
      </div>
    );
  }
export default RenderColumn