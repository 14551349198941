import React, { useState } from 'react';
import './AppBar.css';
import logo from './logo.png'; // Import your logo image
import { Link } from 'react-router-dom';

const AppBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>

    <div className="app-bar">
      
      <img src={logo} alt="Logo.png" className="logo" />

      <div className="center-container">
        <Link to="/HomePage" className="menu-item">
          Home
        </Link>
        <Link to="/AboutPage" className="menu-item">
          About us
        </Link>
  <Link to="/ServicesPage" className="menu-item">
    Services
  </Link>
        {/* <Link to="/CommunityPage" className="menu-item">
          Pricing
        </Link> */}
        <Link to="/StorePage" className="menu-item">
          Businesses
        </Link>
       <div className='menu-item'>  <Link to="/Free"   >
        <button className="glowing-button" >Apply</button>
        </Link></div>
      
       
      </div>
      
      <div className="icons-right">
      <div className='menu-item1'>
  <script src="https://cdn.lordicon.com/lordicon-1.2.0.js"></script>
  <lord-icon
    src="https://cdn.lordicon.com/fkdzyfle.json"
    trigger="hover"
    style={{ width: '38x', height: '38px' }} 
  />
</div>
<div className='menu-item2' onClick={toggleDrawer}>
  <script src="https://cdn.lordicon.com/lordicon-1.2.0.js"></script>
  <lord-icon
    src="https://cdn.lordicon.com/jnikqyih.json"
    trigger="hover"
    style={{width:'38px',height:'38px'}}
  />
</div>

      </div>

      {isDrawerOpen && <LeftDrawer onClose={toggleDrawer} />}
    </div>
    </div>
  );
};

const LeftDrawer = ({ onClose }) => {
  // Dummy profile data
  const userProfile = {
    firstName: 'John',
    lastName: 'Doe',
    email: 'johndoe@example.com',
    profilePicture: 'https://via.placeholder.com/150', // Placeholder URL
  };

  return (
    <div className="left-drawer open">
      {/* Profile view summary */}
      {/* <div className="profile-summary">
        <div className='avatar'>
        <img src={userProfile.profilePicture} alt="Profile" className="profile-picture" /></div>
        <div className="profile-details">
          <p>{`${userProfile.firstName} ${userProfile.lastName}`}</p>
          <p>{userProfile.email}</p>
        </div>
        
      </div> */}
      {/* <Link to="/edit-profile" className="edit-profile-button" onClick={onClose}>
       Edit Profile
      </Link>
     */}
      {/* Drawer content */}
      <Link to="/HomePage" className="menu-item7" onClick={onClose}>
        Home
      </Link>
      <Link to="/AboutPage" className="menu-item7" onClick={onClose}>
        About us
      </Link>
      <Link to="/ServicesPage" className="menu-item7" onClick={onClose}>
        Services
      </Link>
      {/* <Link to="/CommunityPage" className="menu-item7" onClick={onClose}>
        Pricing
      </Link> */}
 
      <Link to="/StorePage"  className="menu-item7" onClick={onClose}>
          Businesses
        </Link>

        
        <Link to="/Free"   >
        <button className="glowing-button" >Apply</button>
        </Link>
       
 
      {/* Add a close button to hide the drawer */}
      <button onClick={onClose} className="close-drawer-button">
        Close
      </button>
    </div>
  );
};

export default AppBar;
