import AppBar from "../../../appbar/AppBar";
import "../../CommunityPage.css";
import * as React from "react";
import MyComponent from "../../../footer/page2";
import ContainerRow2 from "../../../widgets/container2/Homepage2";
import boardw from "../../../assets/images/bill3.png";
import { Helmet } from 'react-helmet';
import Pricing from "../../tabs/index";
import Pricing2 from "./Pricing2";
import Pricing3 from "./Pricing3";

function Mobile() {
  return (
    <>
       <Helmet>
      <title>Sahara Mobile Development Pricing</title>
  <meta name="keywords" content="Creative Agency, logo, website development" />
  <meta name="description" content="Elevate Your Brand with Sahara Sculptors - Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta name="keywords" content="Sahara Sculptors, branding, CRM solutions, digital marketing, 360-degree branding"/>
  <meta name="author" content="Sahara Sculptors"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <meta property="og:title" content="Sahara Sculptors - Elevate Your Brand"/>
  <meta property="og:description" content="Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta property="og:image" content="URL to your image"/>
  {/* Add other meta tags as needed */}
</Helmet>
      <AppBar />
      <div className="content1">
  <div className="centered-column">
    <div className=" heading-pricing">
      Building a mobile application requires strategic thinking and careful planning.
    </div>
    <div className=" sub-heading-pricing">
      At SaharaSculptors, we specialize in mobile application development, offering tailored solutions to meet the unique needs of our clients. Whether you're looking to develop a native app, hybrid app, or progressive web app, we have the expertise to bring your vision to life.
    </div>
    <div className=" heading-pricing2">
      Mobile App Development Services <br />
      Simple, Transparent Pricing
    </div>
    <Pricing2/>
    <Pricing3/>
  </div>
</div>



      {ContainerRow2(<div className="billboard">   <img src={boardw} alt="billborad.png" className="billboard" /></div>)};
      <MyComponent></MyComponent>
    </>
  );
}
export default Mobile