import "./HomePage.css";
import "./Review.css";
import laptop from "../assets/images/laptops.png";
import AppBar from "../appbar/AppBar";
import MyComponent from "../footer/page2";
import phone from "../assets/images/phone.png";
import ContainerRow3 from "../widgets/container3/Containerrow3";
import ContainerRow6 from "../widgets/container6/Containerrow6";
import ContainerRow8 from "../widgets/container8/Containerrow8";
import ContainerRow9 from "../widgets/container9/ContainerRow9";
import ContainerRow10 from "../widgets/container10/ContainerRow10";
import AnimatedHeadings from "../widgets/animated/animated"
import picture1 from "../assets/images/business.png"
import picture2 from "../assets/images/company.png"
import picture3 from "../assets/images/guid.png"
import CookieConsent from '../widgets/cookies/Cookies';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { FaArrowUp } from 'react-icons/fa';

function HomePage() {
  const [showScrollButton, setShowScrollButton] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>

      <div className="coming-soon-container">
      <Helmet>
      <title>Sahara Sculptors Creative Agency</title>
  <meta name="keywords" content="Creative Agency, logo, website development" />
  <meta name="description" content="Elevate Your Brand with Sahara Sculptors - Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta name="keywords" content="Sahara Sculptors, branding, CRM solutions, digital marketing, 360-degree branding"/>
  <meta name="author" content="Sahara Sculptors"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <meta property="og:title" content="Sahara Sculptors - Elevate Your Brand"/>
  <meta property="og:description" content="Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta property="og:image" content="URL to your image"/>
  {/* Add other meta tags as needed */}
</Helmet>

        <AppBar />
        <AnimatedHeadings />
        <div className="ads">
          <div class="onlinehead1 ">
          <div className="online" onClick={() => scrollToSection('360 Degree')}>
              <p>360 Degree</p>
            </div>


            <div className="online" onClick={() => scrollToSection('Billboard')}>
              <p>Billboard</p>
            </div>

            <div className="online" onClick={() => scrollToSection('Social Media')}>
              <p>Social Media</p>
            </div>

            <div className="online" onClick={() => scrollToSection('CRM')}>
              <p>CRM</p>
            </div>

            <div className="online" onClick={() => scrollToSection('SEO')}>
              <p>SEO</p>
            </div>
          </div>
          <div class="onlinehead">
            <div className="online" onClick={() => scrollToSection('Ads')}>
              <p>Local to Global</p>
            </div>
            <div className="online" onClick={() => scrollToSection('Ads')}>
              
              <p>Data-Driven Insights</p>
            </div>
            <div className="online" onClick={() => scrollToSection('Ads')}>
              <p>Tailored Transformation</p>
            </div>
          </div>
        </div>
        <div className="button-enquire">
  <div className="buttoncontact">
    <a href="https://wa.me/c/27656604895" target="_blank" rel="noopener noreferrer">
      <p>Enquire Now</p>
    </a>
  </div>
</div>

        <div className="enquire">
          <div class="trywords">
            <p>Whatsapp  Sahara and elevate your brand</p>
          </div>
        </div>
        <div className="laptop">
          <img src={laptop} alt="laptop.png" className="laptoppicture" />
        </div>
        <div className="phone">
          <img src={phone} alt="phone.png" className="phones" />
        </div>
      </div>

      <div className="">     <div className="centered-column">

        <div id="360 Degree" class="centered-column">
        {ContainerRow3()}</div>



        <div id="CRM" class="centered-column">
          {ContainerRow6()}
          </div>
          <div id="Commercials" class="centered-column">
          {ContainerRow8()}
          </div>
          <div id="SEO" class="centered-column">
          {ContainerRow9()}
          </div>
          <div id="Social Media" class="centered-column">
          {ContainerRow10()}
          </div>
          {/* <div id="Billboard" class="section">
          <div className="billboard7">
      <img src={purples2} alt="purple.png" className="billboard" />
 </div>  
 </div> */}
 <div id="Ads" class="centered-column">
          {ContainerRow4()}
          </div>

          <div className="fixed-button-container">
        {showScrollButton && (
          <div className="scroll-button" onClick={scrollToTop}>
            <FaArrowUp />
          </div>
        )}
      </div>

          <MyComponent />
        </div>
       
      </div>
   
       
    
       <CookieConsent />
    </>
  );

  

  function ContainerRow4() {
    return (
      <div className="bxex">
      <BlogPost1 {...blogPost1} />
<BlogPost2 {...blogPost2} />
<BlogPost3 {...blogPost3} />
      </div>
    );
  }function BlogPost1({ date, title, description, tags }) {
    return (
      <div className="newsletter-container">
        <img
          className="newsletter-image"
          src={picture1}
          alt="Newsletter"
        />
        <div className="newsletter-content">
          <div className="newsletter-date">{date}</div>
          <div className="newsletter-title">{title}</div>
          <div className="newsletter-description">{description}</div>
          <div className="newsletter-tags">
            {tags.map((tag) => (
              <div key={tag} className="newsletter-tag">
                {tag}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }}function BlogPost2({ date, title, description, tags }) {
    return (
      <div className="newsletter-container">
        <img
          className="newsletter-image"
          src={picture2}
          alt="Newsletter"
        />
        <div className="newsletter-content">
          <div className="newsletter-date">{date}</div>
          <div className="newsletter-title">{title}</div>
          <div className="newsletter-description">{description}</div>
          <div className="newsletter-tags">
            {tags.map((tag) => (
              <div key={tag} className="newsletter-tag">
                {tag}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }function BlogPost3({ date, title, description, tags }) {
    return (
      <div className="newsletter-container">
        <img
          className="newsletter-image"
          src={picture3}
          alt="Newsletter"
        />
        <div className="newsletter-content">
          <div className="newsletter-date">{date}</div>
          <div className="newsletter-title">{title}</div>
          <div className="newsletter-description">{description}</div>
          <div className="newsletter-tags">
            {tags.map((tag) => (
              <div key={tag} className="newsletter-tag">
                {tag}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  const blogPost1 = {
  
    date: "19 Jan 2023",
    title: "Business Profile Design and Registration",
    description:
      "Discover the ultimate guide to troubleshooting common issues in our latest blog post. From connectivity malfunctions, we provide step-by-step solutions to the seamless functionality of your smart home system. insights, practical tips",
    tags: ["360 Branding", "Architecture", "Subscriptions"],
  };
  
  const blogPost2 = {
  
    date: "22 Feb 2023",
    title: "The Art of Social Media Engagement",
    description:
      "Unlock the secrets to building a thriving online community. Learn how to create engaging content, connect with your audience, and boost your social media presence. Explore the latest trends and strategies for social media success.",
    tags: ["Social Media", "Engagement", " social Strategy"],
  };
  
  const blogPost3 = {

    date: "15 Mar 2023",
    title: "Maximizing Your Digital Presence and engagement",
    description:
      "Discover the power of a strong digital presence for your business. Dive into effective website design, compelling content creation, and seamless user experiences. Elevate your online brand and leave a lasting impression.",
    tags: ["Digital Presence", "Website Design", "Experience"],
  };
export default HomePage
