import React from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import './shortcodes.css'; // Import the CSS for styling
import PricingBox from "./pricingbox";
import PricingBox2 from "./pricebox2";
import PricingBoxAnnual from "./pricingbox copy";
import PricingBox2Annual from "./pricebox2 copy";

function Pricing() {
  return (
    <section className="pricing-section">
      <div className="tf-container st3">
        <div className="row">
          <div className="col-lg-12">
            <Tabs className="group-pricing-v1 tf-tab">

              <TabList className="menu-tab">
                <Tab className="ct-tab">Monthly</Tab>
                <Tab className="ct-tab">Once Off</Tab>
              </TabList>
              <div className="content-tab">
                <TabPanel className="inner animation-tab">
                  <div className="group-col-3">
                    <div className="spacextv">
                  <PricingBox
  tag1="Budget Friendly"
  tag2="Popular"
  pricing="R1500.00"
  features={[
    'Brand Guideline Creation ',
    'Brand Positioning',
    'Brand Collateral Design',
    'Premium Support 24/7 ',]}
  linkText="View More"
  linkTo="/Branding"
/></div>
<PricingBox2
  tag1="Comprehensive"
  tag2="Popular"
  pricing="R2 250.00"
  features={[
   
    ' All Budget Friendly Services ',
    'Tagline Development',
    'Printed Merchandise Design',
    'Copelling Brand Story Telling',
    
  ]}
  linkText="View More"
  linkTo="/Branding"
/>

<div className="spacextv">

                  
<PricingBox
  tag1="Premium"
  tag2="Popular"
  pricing="R7 200.00"
  features={[
    'All Comprehensive Services',
    'Basic CRM implimentation',
    'Website Design & Development ',
    'Basic SEO  ',
    
   
  ]}
  linkText="View More"
  linkTo="/Branding"
/>
</div>    
                  </div>
                </TabPanel>
                <TabPanel className="inner animation-tab">
                  <div className="group-col-3">
                  <PricingBoxAnnual
tag1="Budget Friendly"
tag2="Popular"
pricing="R7 500.00"
features={[
  '30 Job posting',
  '3 featured job',
  'Job displayed for 15 days',
  'Brand Audit and Analysis'
]}
linkText="View More"
linkTo="/Branding"
/>
<PricingBox2Annual
tag1="Comprehensive"
tag2="Popular"
pricing="R12 200.00"
features={[
  '40 Job posting',
  '5 featured job',
  'Job displayed for 30 days',
  'Premium Support 24/7'
]}
linkText="View More"
linkTo="#"
/>

                
<PricingBoxAnnual
tag1="Premium"
tag2="Popular"
pricing="R34 400.00"
features={[
  '50 Job posting',
  '10 featured job',
  'Job displayed for 60 days',
  'Premium Support 24/7'
]}
linkText="View More"
linkTo="#"
/>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
