import AppBar from "../../../appbar/AppBar";
import "../../CommunityPage.css";
import * as React from "react";
import MyComponent from "../../../footer/page2";
import ContainerRow2 from "../../../widgets/container2/Homepage2";
import boardw from "../../../assets/images/bill3.png";
import { Helmet } from 'react-helmet';
import Pricing from "../../tabs/index";
import Pricing2 from "./Pricing2";
import Pricing3 from "./Pricing3";

function Branding() {
  return (
    <>
       <Helmet>
      <title>Sahara Pricing Branding</title>
  <meta name="keywords" content="Creative Agency, logo, website development" />
  <meta name="description" content="Elevate Your Brand with Sahara Sculptors - Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta name="keywords" content="Sahara Sculptors, branding, CRM solutions, digital marketing, 360-degree branding"/>
  <meta name="author" content="Sahara Sculptors"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <meta property="og:title" content="Sahara Sculptors - Elevate Your Brand"/>
  <meta property="og:description" content="Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta property="og:image" content="URL to your image"/>

</Helmet>
      <AppBar />
      <div className="content1">
        <div className="centered-column">
          <div className=" heading-pricing">
            Branding should be in anyone's thoughts before starting a business <br />
            
          </div>
          
          <div className=" sub-heading-pricing">
          At SaharaSculptors, we offer a comprehensive suite of services designed to <br/>meet the diverse branding needs of our clients,
            Compare our options and select the one that meets your needs.
          </div>
          <div className=" heading-pricing2">
           360 Branding Packages <br />
            Simple,Transparent pricing
          </div>
       
           <Pricing2/>
           <Pricing3/>
          
        </div>
        
      </div>


      {ContainerRow2(<div className="billboard">   <img src={boardw} alt="billborad.png" className="billboard" /></div>)};
      <MyComponent></MyComponent>
    </>
  );
}
export default Branding