
import { FaShareAlt ,FaThumbsUp,FaDownload } from 'react-icons/fa';
import {  FaTimesCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import ContainerRow2 from "../widgets/container2/Homepage2";
import { FaLock } from 'react-icons/fa';

import React ,{useState}from "react";
const Card = ({ dataImage, productName, productDescription, productdeschead, avatarUrl, fullName }) => {
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);
    const [mouseLeaveDelay, setMouseLeaveDelay] = useState(null);
    const [showDescription, setShowDescription] = useState(false);
    const [isPopupVisible, setPopupVisibility] = useState(false);
  
    const handleMouseMove = (e) => {
      setMouseX(e.pageX - e.currentTarget.offsetLeft - e.currentTarget.offsetWidth / 2);
      setMouseY(e.pageY - e.currentTarget.offsetTop - e.currentTarget.offsetHeight / 2);
    };
  
    const handleMouseEnter = () => {
      clearTimeout(mouseLeaveDelay);
    };
  
    const handleMouseLeave = () => {
      const delay = setTimeout(() => {
        setMouseX(0);
        setMouseY(0);
      }, 1000);
      setMouseLeaveDelay(delay);
    };
  
  
    const handleHideDescription = () => {
      setShowDescription(false);
    };
  
    const handleCardClick = () => {
      setPopupVisibility(!isPopupVisible);
    };
  
    const handleOverlayClick = () => {
      setPopupVisibility(false);
    };
  
    const mousePX = mouseX / 240;
    const mousePY = mouseY / 320;
  
    const cardStyle = {
      transform: `rotateY(${mousePX * 30}deg) rotateX(${mousePY * -30}deg)`,
    };
  
    const cardBgTransform = {
      transform: `translateX(${mousePX * -40}px) translateY(${mousePY * -40}px)`,
    };
  
    const cardBgImage = {
      backgroundImage: `url(${dataImage})`,
    };
  
    return (
      <div className="all-card">
  
        {isPopupVisible && (
          <div>
          <div className="popup-container" onClick={handleOverlayClick}>
         
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <div className="everything">
        
            <div className="everything2">
            <div className="button-close">
            <button className="close-button" onClick={handleCardClick}>
                
                <FaTimesCircle />
              </button>
              </div>
  
              <div className="avatar-info33">
                <img className="avatar33" src={avatarUrl} alt="Avatar" />
                <div>
                  <div className="product-name">{productName}</div>
                  <div className="name">{fullName}</div>
                </div>
            
              </div>
          
              <div className="bill22">
              <div className="product-name">{productName}</div>
           
              
              </div>
         
              {ContainerRow2(<div className="billboard990">   <img src={dataImage} alt="billborad.png" className="billboard" /></div>)}
              <div className="overview">
              <div className="product-name">{productDescription}</div>      </div>
              <div className="bill">
   
              {ContainerRow2(<div className="billboard90">   <img src={dataImage} alt="billborad.png" className="billboard" /></div>)}
  
              {ContainerRow2(<div className="billboard90">   <img src={dataImage} alt="billborad.png" className="billboard" /></div>)}
          
              </div>
              <div className="iconss">

              </div>
  
              <div className="subscribe">Contact us to Buy premium assets tailored for your business  < FaLock  className="icon5" />
                  </div>
  
                  <div className= "subscribe">
                  <div className="avatar-info33">
                <img className="avatar33" src={avatarUrl} alt="Avatar" />
                <div>
                  <div className="product-name">{productName}</div>
                  <div className="name">{fullName}</div>
                </div>
            
              </div>
              </div>
            
              </div>
              </div>
            </div>
          </div>
          </div>
        )}
        <div
          className="card-wrap"
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div>
            <div className="card" style={cardStyle} onClick={handleCardClick}>
              <div className="card-bg" style={{ ...cardBgTransform, ...cardBgImage }}></div>
            </div>
          </div>
        </div>
        <div className="card-info4">
          <div className="product-name2">
            {showDescription && (
              <div className="description-popup">
                <div className="description-content">
                  <span className="cancel-icon" onClick={handleHideDescription}>
                    <FaTimesCircle />
                  </span>
                </div>
              </div>
            )}
            {!showDescription && (
              <>
                <Tooltip effect="solid" place="top" />
                <div className="avatar-info33">
                  <img className="avatar33" src={avatarUrl} alt="Avatar33" />
                  <div>
                    <div className="product-name">{productName}</div>
                    <div className="name">{fullName}</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
    
  };
  export default Card