import React from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import './shortcodes.css'; // Import the CSS for styling
import PricingBox from "./pricingbox";
import PricingBox2 from "./pricebox2";
import PricingBoxAnnual from "./pricingbox copy";
import PricingBox2Annual from "./pricebox2 copy";
function Pricing10() {
  return (
    <section className="pricing-section">
    <div className="tf-container st3">
      <div className="row">
        <div className="col-lg-12">
          <Tabs className="group-pricing-v1 tf-tab">

            <TabList className="menu-tab">
              <Tab className="ct-tab">Monthly</Tab>
              <Tab className="ct-tab">Once Off</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
                <div className="group-col-3">
                <PricingBox
tag1="Budget Friendly"
tag2="Popular"
pricing="R2 000.00"
features={[
  'Cloud Based CRM',
  'Contact Management system',
  'User Training ',
  'Standard data encryption'
]}
linkText="View More"
linkTo="/CRM"
/>
<PricingBox2
tag1="Comprehensive"
tag2="Popular"
pricing="R5 800.00"
features={[
  'Sales pipeline management',
  'Onboarding Sessions,user Guides',
  'Custom DashBoard',
  'News letter Campaigns'
]}
linkText="View More"
linkTo="/CRM"
/>

                
<PricingBox
tag1="Premium"
tag2="Popular"
pricing="R14 000.00"
features={[
  'Customized CRM Solution',
  'Tailored modules,API integrations',
  'ERP Systems & third Party',
  'Enhanced Security Protocols'
]}
linkText="View More"
linkTo="/CRM"
/>
             
                </div>
              </TabPanel>
              <TabPanel className="inner animation-tab">
                <div className="group-col-3">
                <PricingBoxAnnual
tag1="Budget Friendly"
tag2="Popular"
pricing="R10 000.00"
features={[
  'Cloud Based CRM',
  'Contact Management system',
  'User Training ',
  'Standard data encryption'
]}
linkText="View More"
linkTo="/CRM"
/>
<PricingBox2Annual
tag1="Comprehensive"
tag2="Popular"
pricing="R29 000.00"
features={[
  'Sales pipeline management',
  'Onboarding Sessions,user Guides',
  'Custom DashBoard',
  'News letter Campaigns'
]}
linkText="View More"
linkTo="/CRM"
/>

                
<PricingBoxAnnual
tag1="Premium"
tag2="Popular"
pricing="R70 000.00"
features={[
  'Customized CRM Solution',
  'Tailored modules,API integrations',
  'ERP Systems & third Party',
  'Enhanced Security Protocols'
]}
linkText="View More"
linkTo="/CRM"
/> 
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Pricing10;
