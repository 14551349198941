import React from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import './shortcodes.css'; // Import the CSS for styling
import PricingBox from "./pricingbox";
import PricingBox2 from "./pricebox2";
import PricingBoxAnnual from "./pricingbox copy";
import PricingBox2Annual from "./pricebox2 copy";
function Pricing4() {
  return (
    <section className="pricing-section">
    <div className="tf-container st3">
      <div className="row">
        <div className="col-lg-12">
          <Tabs className="group-pricing-v1 tf-tab">

            <TabList className="menu-tab">
              <Tab className="ct-tab">Monthly</Tab>
              <Tab className="ct-tab">Once Off</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
                <div className="group-col-3">
                <PricingBox
tag1="Budget Friendly"
tag2="Popular"
pricing="R3 500"
features={[
  '3-5 Responsive Pages',
  'Basic CMS Setup',
  'Basic onPage SEO Setup',
  'Email & Phone Support'
]}
linkText="View More"
linkTo="/Website"
/>
<PricingBox2
tag1="Comprehensive"
tag2="Popular"
pricing="R16 000.00"
features={[
  '5-20 Resposive Pages',
  'CMS with Custom Capabilities',
  'SEO optimization (all pages)',
  'Premium  Support 24/7'
]}
linkText="View More"
linkTo="/Website"
/>

                
<PricingBox
tag1="Premium"
tag2="Popular"
pricing="R80 000.00"
features={[
  'E-commerce Website',
  'SEO (All pages/components)',
  'Payment Gateway & Deliveries',
  'Admin Development & Design'
]}
linkText="View More"
linkTo="/Website"
/>
             
                </div>
              </TabPanel>
              <TabPanel className="inner animation-tab">
                <div className="group-col-3">
                <PricingBoxAnnual
tag1="Budget Friendly"
tag2="Popular"
pricing="R16 000"
features={[
  '3-5 Responsive Pages',
  'Basic CMS Setup',
  'Basic onPage SEO Setup',
  'Email & Phone Support'
]}
linkText="View More"
linkTo="/Website"
/>
<PricingBox2Annual
tag1="Comprehensive"
tag2="Popular"
pricing="R75 000.00"
features={[
  '5-20 Resposive Pages',
  'CMS with Custom Capabilities',
  'SEO optimization (all pages)',
  'Premium  Support 24/7'
]}
linkText="View More"
linkTo="/Website"
/>

                
<PricingBoxAnnual
tag1="Premium"
tag2="Popular"
pricing="R429 000.00"
features={[
  'E-commerce Website',
  'SEO (All pages/components)',
  'Payment Gateway & Deliveries',
  'Admin Development & Design'
]}
linkText="View More"
linkTo="/Website"
/>
             
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Pricing4;
