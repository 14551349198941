import React from 'react';
import logo from './assets/images/logo.png';
const Navigation = () => {
  return (
    <>
    <img src={logo} alt="Logo.png" className="logo" />
      
    <div className="div-13">

   
  
    
      <div className="div-14">
        <div className="div-15">Company</div>
        <div className="div-16">
          <a href="/AboutPage" className="nav-link">
            <div className="div-17">About us</div>
          </a>
          <a href="/ServicesPage" className="nav-link">
            <div className="div-17">Services</div>
          </a>
          <a href="/StorePage" className="nav-link">
            <div className="div-17">Our Blog</div>
          </a>
          <a href="/CommunityPage" className="nav-link">
            <div className="div-17">Pricing</div>
          </a>
        </div>
      </div>
      <div className="div-14">
        <div className="div-15">Legal</div>
        <div className="div-16">
          <a href="https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/Terms%20of%20use.txt?alt=media&token=e4e78047-1e83-4832-bbcd-ce07fdc93269" className="nav-link">
            <div className="div-17">Terms</div>
          </a>
          <a href="https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/privacy.txt?alt=media&token=fa5636b9-4ae5-40fe-8898-a971e3e9cad6" className="nav-link">
            <div className="div-17">Privacy</div>
          </a>
          <a href="https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/cookies.txt?alt=media&token=2e575ddb-fe05-4ed1-8528-ff3baaa9131e" className="nav-link">
            <div className="div-17">Cookies</div>
          </a>
          <a href="/license" className="nav-link">
            <div className="div-17">License</div>
          </a>
        </div>
      </div>
    </div>
    </>
  );
};

export default Navigation;
