import * as React from "react";
import "./page2.css";
import Navigation from "../Page2child";
import {
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaTiktok,
  FaGoogle,
  FaPinterest,

  FaBuffer,
} from "react-icons/fa";

function MyComponent() {
  return (
    <div className="container99">
      <footer className="footer">{newFunction()}    {newFunction_3()}</footer>
    </div>
  );

  function newFunction() {
    return (
      
      <div className="footer-son">
        <div className="">
        {newFunction_1()}
        {newFunction_2()}

    
        </div>
        <div>  {Navigation()}</div>
      </div>
    );
  }

  function newFunction_3() {
    return (
  
        <div className="div-29">
          <div>©2024 Saharasculptors. All rights reserved.</div>
       
        </div>
  
    );
  }

  function newFunction_2() {
    return (
      <div className="div-9">
        <div className="div-10">
          <div className="div-5">
            Join the league of success stories. Sahara Sculptors - where every <br/>business is a work of art..{" "}
          </div>
          <div className="div-12">
            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FSaharaSculptors" className="icon-link">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com/saharasculptors_/" className="icon-link">
              <FaInstagram />
            </a>
            <a href="https://web.facebook.com/Saharasculptors?_rdc=1&_rdr" className="icon-link">
              <FaWhatsapp />
            </a>
            <a href="https://www.tiktok.com/@saharasculptors?_t=8h8XnXJqJ6M&_r=1" className="icon-link">
              <FaTiktok />
            </a>
            <a href="https://www.google.com/maps/place/SaharaSculptors/" className="icon-link">
              <FaGoogle />
            </a>
            <a href="https://za.pinterest.com/saharasculptors/" className="icon-link">
              <FaPinterest />
            </a>
           
            <a href="https://saharasculptors.start.page/" className="icon-link">
              <FaBuffer />
            </a>
          </div>
        </div>
      
      </div>
    );
  }
  function newFunction_1() {
    return (
      <div className="div-3">
        <div className="div-4">
          Stay Connected with Our Team on the chat desk
        </div>
        <div className="div-5">
          Contact our Agents anytime anywhere, Sahara team is always online
        </div>
      </div>
    );
  }
}

export default MyComponent;
