import React, { useEffect } from 'react';
import picture1 from "../assets/images/social.png";
import picture2 from "../assets/images/social2.png";

import picture3 from "../assets/images/email.png";

import picture4 from "../assets/images/product.png";

import picture5 from "../assets/images/webs.png";

import picture6 from "../assets/images/grow.png";

function ContainerRow8() {
  return (
    <div className="bxexd">
      { HeadingPost()}
    

  <div className="port-card">
    <div className="card-port">
    <div className="picture-placeholder">
  <img className="yello-picture44" src={picture2} alt="social" />
</div>
<div className="toloki">
  <div className="tittle-port">
    <h3>Social Media Management and Advertising </h3>
  </div>
  <div className="tittle-sub">
Engage audience and optimize campaigns<br/>
Strategic advertising<br/>
 solutions<br/>
    </div>
    </div>
  </div>
  <div className="card-port">

  <div className="picture-placeholder">
  <img className="yello-picture44" src={picture1} alt="social" />
</div>
<div className="toloki">
  <div className="tittle-port">
    <h3>Digital Presence Enhancement Suite </h3>
  </div>
  <div className="tittle-sub">
  At Sahara Sculptors, we redefine online excellence <br/>
  DP Enhancement Suite<br/>
 solutions<br/>
    </div>
    </div>
  </div>
  </div>
 
 
  


  <div className="port-card">
    <div className="card-port">
  <div className="picture-placeholder">
  <img className="yello-picture44" src={picture3} alt="social" />
</div>

<div className="toloki">
  <div className="tittle-port">
    <h3>Email Marketing Campaigns </h3>
  </div>
  <div className="tittle-sub">
  Unlock the potential of personalized communication<br/>
  eCommerce
    <br/>
    solutions<br/>
    
    </div>
    </div>
  </div>
  <div className="card-port">
  <div className="picture-placeholder">
  <img className="yello-picture44" src={picture4} alt="social" />
</div>

<div className="toloki">
  <div className="tittle-port">
    <h3>Product Branding</h3>
  </div>
  <div className="tittle-sub">
  Elevate your product's identity with Sahara Sculptors<br/>
  Strategic Product Branding
    <br/>
    solutions<br/>
    </div>
    </div>
  </div>
  </div>
 
 
  

  <div className="port-card">
    <div className="card-port">

    <div className="picture-placeholder">
  <img className="yello-picture44" src={picture5} alt="social" />
</div>

<div className="toloki">
  <div className="tittle-port">
    <h3>Website Design and Development</h3>
  </div>
  <div className="tittle-sub">
  Experience excellence in online presence <br/>
 full production scale
    <br/>
    solutions<br/>
    
    </div>
    </div>
  </div>
  <div className="card-port">
  <div className="picture-placeholder">
  <img className="yello-picture44" src={picture6} alt="social" />
</div>

<div className="toloki">
  <div className="tittle-port">
    <h3>Domain Dominance Solutions</h3>
  </div>
  <div className="tittle-sub">
  Unlock the full potential of your digital presence <br/>
 SEO
    <br/>
    solutions<br/>
    </div>
    </div>
  </div>
  </div>
 
 
  


  
</div>

  
  );}
  const HeadingPost = () => {
    useEffect(() => {
      const headingPort = document.querySelector('.heading-port');
  
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      }, { threshold: 0.5 });
  
      observer.observe(headingPort);
  
      return () => {
        observer.disconnect();
      };
    }, []); // Run the effect once on mount
  
    return (
      <div className="heading-port">
          Lead your business<br />
      where it truly belongs.
      </div>
    );
  };
          
  
export default ContainerRow8;
