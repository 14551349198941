import React from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import './shortcodes.css'; // Import the CSS for styling
import PricingBox from "./pricingbox";
import PricingBox2 from "./pricebox2";
import PricingBoxAnnual from "./pricingbox copy";
import PricingBox2Annual from "./pricebox2 copy";
function Pricing5() {
  return (
    <section className="pricing-section">
    <div className="tf-container st3">
      <div className="row">
        <div className="col-lg-12">
          <Tabs className="group-pricing-v1 tf-tab">

            <TabList className="menu-tab">
              <Tab className="ct-tab">Monthly</Tab>
              <Tab className="ct-tab">Once Off</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
                <div className="group-col-3">
                <PricingBox
tag1="Budget Friendly"
tag2="Popular"
pricing="R27 000.00"
features={[
  'Android Basic',
  '2- 3 core features',
  'Basic Login & Testing',
  'App Stores & Sahara App Store'
]}
linkText="View More"
linkTo="/Mobile"
/>
<PricingBox2
tag1="Comprehensive"
tag2="Popular"
pricing="R116 000.00"
features={[
  'Android & IOS Custom UI/UX',
  '5- 7 core features',
  'Advanced Testing & Secure Auth',
 ' External APIs & Custom DataBase'
]}
linkText="View More"
linkTo="/Mobile"
/>

                
<PricingBox
tag1="Premium"
tag2="Popular"
pricing="R224 600.00"
features={[
  'Cross-platform(Android & IOS)',
  'Custom Backend Development',
  'Desktop Admin',
  'Premium Support 24/7'
]}
linkText="View More"
linkTo="/Mobile"
/>
             
                </div>
              </TabPanel>
              <TabPanel className="inner animation-tab">
                <div className="group-col-3">
                <PricingBox
tag1="Budget Friendly"
tag2="Popular"
pricing="R125 000.00"
features={[
  'Android Basic',
  '2- 3 core features',
  'Basic Login & Testing',
  'App Stores & Sahara App Store'
]}
linkText="View More"
linkTo="/Mobile"
/>
<PricingBox2Annual
tag1="Comprehensive"
tag2="Popular"
pricing="R519 000.00"
features={[
  'Android & IOS Custom UI/UX',
  '5- 7 core features',
  'Advanced Testing & Secure Auth',
 ' External APIs & Custom DataBase'
]}
linkText="View More"
linkTo="/Mobile"
/>

                
<PricingBoxAnnual
tag1="Premium"
tag2="Popular"
pricing="R1 120 000.00"
features={[
  'Cross-platform(Android & IOS)',
  'Custom Backend Development',
  'Desktop Admin',
  'Premium Support 24/7'
]}
linkText="View More"
linkTo="/Mobile"
/>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Pricing5;
