import React from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import './shortcodes.css'; // Import the CSS for styling
import PricingBox from "./pricingbox";
import PricingBox2 from "./pricebox2";
import PricingBoxAnnual from "./pricingbox copy";
import PricingBox2Annual from "./pricebox2 copy";
function Pricing6() {
  return (
    <section className="pricing-section">
    <div className="tf-container st3">
      <div className="row">
        <div className="col-lg-12">
          <Tabs className="group-pricing-v1 tf-tab">

            <TabList className="menu-tab">
              <Tab className="ct-tab">Monthly</Tab>
              <Tab className="ct-tab">Once Off</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
                <div className="group-col-3">
                <PricingBox
tag1="Budget Friendly"
tag2="Popular"
pricing="R3 500.00"
features={[
  'Social Media Setup',
  '5-7 posts per week',
  '12+ social platform',
  'Email Support'
]}
linkText="View More"
linkTo="/Social"
/>
<PricingBox2
tag1="Comprehensive"
tag2="Popular"
pricing="R7 500.00"
features={[
  'Sculpted Ads Campaign',
  '7-14 posts per week',
  'Competitor Analysis',
  'Social Media WorkShop'
]}
linkText="View More"
linkTo="/Social"
/>

                
<PricingBox
tag1="Premium"
tag2="Popular"
pricing="R27 000.00"
features={[
  'Premium Support 24/7',
  'Advanced Ad Campaigns',
  'Daily Posts(picture/videos)',
  'Influencer partnership'
]}
linkText="View More"
linkTo="/Social"
/>
             
                </div>
              </TabPanel>
              <TabPanel className="inner animation-tab">
                <div className="group-col-3">
                <PricingBoxAnnual
tag1="Budget Friendly"
tag2="Popular"
pricing="R33 600.00"
features={[
  'Social Media Setup',
  '5-7 posts per week',
  '12+ social platform',
  'Email Support'
]}
linkText="View More"
linkTo="/Social"
/>
<PricingBox2Annual
tag1="Comprehensive"
tag2="Popular"
pricing="R72 000.00"
features={[
  'Sculpted Ads Campaign',
  '7-14 posts per week',
  'Competitor Analysis',
  'Social Media WorkShop'
]}
linkText="View More"
linkTo="/Social"
/>

                
<PricingBoxAnnual
tag1="Premium"
tag2="Popular"
pricing="R259 200"
features={[
  'Premium Support 24/7',
  'Advanced Ad Campaigns',
  'Daily Posts(picture/videos)',
  'Influencer partnership'
]}
linkText="View More"
linkTo="/Social"
/>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Pricing6;
