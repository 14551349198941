import React, { useState } from 'react';
import './profileview.css'; // Import CSS file for styling

const ProfileView = ({ user }) => {
  // State to manage editable fields
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [bio, setBio] = useState(user.bio);

  // Function to generate a random color
  const getRandomColor = () => {
    const colors = ['#f44336', '#9c27b0', '#3f51b5', '#2196f3', '#009688', '#4caf50', '#ff9800', '#e91e63'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Function to get the first initial of the name
  const getFirstInitial = () => {
    return name ? name.charAt(0).toUpperCase() : '';
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Update user data with edited fields
    // For demonstration, you can replace the console.log with your API call to update user data
    console.log("Updating user data...");
    console.log({ name, email, bio });
    setEditing(false); // Exit editing mode
  };

  return (
    <div className="profile-view99">
      <div className="avatar99" style={{ backgroundColor: getRandomColor() }}>
        {getFirstInitial()}
      </div>
      <h2>Profile</h2>
      <div className="profile-info99">
        {!editing ? (
          <>
            <div><strong>Name:</strong> {name}</div>
            <div><strong>Email:</strong> {email}</div>
            <div><strong>Bio:</strong> {bio}</div>
            <button onClick={() => setEditing(true)}>Edit Profile</button>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label>
              Bio:
              <textarea
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              />
            </label>
            <button type="submit">Save</button>
            <button onClick={() => setEditing(false)}>Cancel</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ProfileView;
