import React, { useState } from 'react';
import AppBar from './AppBar';
import ProfileView from './profilecontent';
import Downloaded from '../community/downloads';

const ProfileScreen = () => {
  const [activeTab, setActiveTab] = useState('Profile');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div>

<AppBar />
      <div className="navigation-menu">
        {/* Define navigation tabs */}
        <div
          className={`nav-item ${activeTab === 'Profile' ? 'active' : ''}`}
          onClick={() => handleTabClick('Profile')}
        >
          Profile
        </div>
        <div
          className={`nav-item ${activeTab === 'Downloads' ? 'active' : ''}`}
          onClick={() => handleTabClick('Downloads')}
        >
          Downloads
        </div>
        <div
          className={`nav-item ${activeTab === 'Favorites' ? 'active' : ''}`}
          onClick={() => handleTabClick('Favorites')}
        >
          Favorites
        </div>
        <div
          className={`nav-item ${activeTab === 'Devices' ? 'active' : ''}`}
          onClick={() => handleTabClick('Devices')}
        >
          Devices
        </div>
        <div
          className={`nav-item ${activeTab === 'Subscription' ? 'active' : ''}`}
          onClick={() => handleTabClick('Subscription')}
        >
         Subscription
        </div>
        {/* Add more navigation tabs as needed */}
      </div>

      {/* Render content based on the selected tab */}
      {activeTab === 'Profile' && <ProfileContent />}
      {activeTab === 'Downloads' && <DownloadsContent />}
      {activeTab === 'Favorites' && <FavoritesContent />}
      {activeTab === 'Subscription' && <SubscriptionContent />}
      {activeTab === 'Devices' && <DeviceContent />}
      {/* Add more content components for other tabs */}
    </div>
  );
};
const user = {
  name: "John Doe",
  email: "john@example.com",
  bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae commodo felis."
};
const ProfileContent = () => {
  return <div> <ProfileView user={user} />
  </div>;
};

const DownloadsContent = () => {
  return  <div className="content1">
  <div className="centered-column"> <div><Downloaded/></div></div></div>;
};
const FavoritesContent = () => {
  return <div>  <div className="centered-column"> <div><Downloaded/></div></div></div>
};
const DeviceContent = () => {
  return <div>Device content goes here</div>;
};
const SubscriptionContent = () => {
  return <div>Subscription content goes here</div>;
};


export default ProfileScreen;
