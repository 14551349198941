import ServiceInfo from "../serviceinfo/containerrowchild";
import ContainerRow2 from "../container2/Homepage2";
import renderColumn from "../reusables/rendercolumn";
import Headings from "../reusables/heading";
import Subheading from "../reusables/subheading";


import friday3 from "../assets/video/Cube.mp4"
import picture from "../assets/images/laptop.png"
function ContainerRow6() {
  return (
    <div className="bxexd">
      <div className="top1">
        <Headings
        className={"left-word2"}
          heading1="Elevate Your Brand "
          heading2="  with Expert CRM"
          heading3="Services"
        />
        <Subheading
        className={"right-word2"}
          subheading1=" Streamline Customer Interactions with Intuitive CRM Solutions."
          subheading2=" Enhance Customer Relationships through Personalized CRM Strategies."
          subheading3=" Optimize Customer Data for Informed Decision-Making."
          subheading4=" Implement Automated CRM Processes for Efficient Business Operations"
        />
      </div>
      <ServiceInfo
        width="100%"
        height="100%"
         picture={picture}
         description="phones"
  title={<>CRM Solutions<br/>with Sahara</>}
  des={
    <>
      Transform your business with Sahara Sculptors' CRM expertise. Our<br/>
      comprehensive CRM solutions are designed to streamline operations,<br/>
      enhance customer relationships, and drive informed decision-making. With<br/>
      intuitive CRM processes and personalized strategies, we empower you to<br/>
      elevate your business to new heights. Welcome to a seamless experience<br/>
      where efficiency meets excellence. Join Sahara Community and unlock the<br/>
      potential of CRM for your success.
    </>
  }
></ServiceInfo>
<div className="lastnumber">
  <div className="lastnumber-kid">
    {renderColumn(
      "CRM Excellence",
      "Optimize operations with our CRM ",
      "Enhance customer relationships ",
      "Transform your business with intuitive"
    )}
  </div>
  <div className="lastnumber-kid">
    {renderColumn(
      "Personalized Strategies",
      "Tailor CRM solutions to your business.",
      "Empower your team with tools designed.",
      "Unlock the potential of CRM for your success."
    )}
  </div>
  <div className="lastnumber-kid">
    {renderColumn(
      "Efficiency Meets Excellence",
      "Streamline operations and boost efficiency.",
      "Experience a new era of professional collaboration.",
      "optimize your business with CRM."
    )}
  </div>
</div>

{ContainerRow2(
  <div className="billboard">
    <video
      autoPlay
      playsInline
      muted
      loop
      style={{ width: '100%', height: '100%', objectFit: "contain" }}
    >
      <source src={friday3} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>

   
)}
    </div>
  );
}
export default ContainerRow6;
