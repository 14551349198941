import React from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import './shortcodes.css'; // Import the CSS for styling
import PricingBox from "./pricingbox";
import PricingBox2 from "./pricebox2";
import PricingBoxAnnual from "./pricingbox copy";
import PricingBox2Annual from "./pricebox2 copy";
function Pricing9() {
  return (
    <section className="pricing-section">
    <div className="tf-container st3">
      <div className="row">
        <div className="col-lg-12">
          <Tabs className="group-pricing-v1 tf-tab">

            <TabList className="menu-tab">
              <Tab className="ct-tab">Monthly</Tab>
              <Tab className="ct-tab">Once Off</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
                <div className="group-col-3">
                <PricingBox
tag1="Budget Friendly"
tag2="Popular"
pricing="R1 500.00"
features={[
  'Basic Keyword analysis',
  'Basic optimization of meta tags',
  'Basic Google My Business Setup',
  'Basic Website Edit'
]}
linkText="View More"
linkTo="/Search"
/>
<PricingBox2
tag1="Comprehensive"
tag2="Popular"
pricing="R2 800.00"
features={[
  'Competitor Keywords Analysis',
  'Optimizing all Website Pages',
  'geo targeted solutions',
  'Site Speed & Mobile Friendliness'
]}
linkText="View More"
linkTo="/Search"
/>

                
<PricingBox
tag1="Premium"
tag2="Popular"
pricing="R6 200.00"
features={[
  'long-tail keywords',
  'Advanced Onpage SEO',
  'High Quality Content',
  'Advanced Technical SEO'
]}
linkText="View More"
linkTo="/Search"
/>
             
                </div>
              </TabPanel>
              <TabPanel className="inner animation-tab">
                <div className="group-col-3">
                <PricingBoxAnnual
                tag1="Budget Friendly"
tag2="Popular"
pricing="R7 500.00"
features={[
  'Basic Keyword analysis',
  'Basic optimization of meta tags',
  'Basic Google My Business Setup',
  'Basic Website Edit'
]}
linkText="View More"
linkTo="/Search"
/>
<PricingBox2Annual
tag1="Comprehensive"
tag2="Popular"
pricing="R14 000.00"
features={[
  'Competitor Keywords Analysis',
  'Optimizing all Website Pages',
  'geo targeted solutions',
  'Site Speed & Mobile Friendliness'
]}
linkText="View More"
linkTo="/Search"
/>

                
<PricingBoxAnnual
tag1="Premium"
tag2="Popular"
pricing="R31 000.00"
features={[
  'long-tail keywords',
  'Advanced Onpage SEO',
  'High Quality Content',
  'Advanced Technical SEO'
]}
linkText="View More"
linkTo="/Search"
/>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Pricing9;
