import React from 'react';
import { Link } from 'react-router-dom';

const PricingBox2Annual = ({ tag1, tag2, pricing, features, linkText, linkTo }) => {
  return (
    <div className="pricing-box active cl3">
      <div className="group-tag">
        <div className="tag1">{tag1}</div>
        <div className="tag2">{tag2}</div>
      </div>
      <div className="pricing">
        {pricing}
        <span>/Once off</span>
      </div>
      <ul className="wd-list-icon">
        {features.map((feature, index) => (
          <li key={index}>
            <span className="icon"><span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                                fill="#EE8B00F1"
                              />
                              <path
                                d="M8.39644 13.8429L5.14644 10.3563C4.95119 10.1468 4.95119 9.80718 5.14644 9.59769L5.85353 8.8391C6.04879 8.62961 6.36539 8.62961 6.56064 8.8391L8.75 11.1878L13.4394 6.1571C13.6346 5.94763 13.9512 5.94763 14.1465 6.1571L14.8536 6.91569C15.0488 7.12516 15.0488 7.46479 14.8536 7.67428L9.10355 13.8429C8.90828 14.0524 8.5917 14.0524 8.39644 13.8429Z"
                                fill="white"
                              />
                            </svg>
                          </span></span>
            {feature}
          </li>
        ))}
      </ul>
      <Link to={linkTo} className="btn">
        {linkText}
      </Link>
    </div>
  );
};

export default PricingBox2Annual;
