import AppBar from "../../../appbar/AppBar";
import "../../CommunityPage.css";
import * as React from "react";
import MyComponent from "../../../footer/page2";
import ContainerRow2 from "../../../widgets/container2/Homepage2";
import boardw from "../../../assets/images/bill3.png";
import { Helmet } from 'react-helmet';
import Pricing from "../../tabs/index";
import Pricing2 from "../Website/Pricing2";
import Pricing3 from "../Website/Pricing3";

function Website() {
  return (
    <>
       <Helmet>
      <title>Sahara Website development Pricing</title>
  <meta name="keywords" content="Creative Agency, logo, website development" />
  <meta name="description" content="Elevate Your Brand with Sahara Sculptors - Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta name="keywords" content="Sahara Sculptors, branding, CRM solutions, digital marketing, 360-degree branding"/>
  <meta name="author" content="Sahara Sculptors"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <meta property="og:title" content="Sahara Sculptors - Elevate Your Brand"/>
  <meta property="og:description" content="Redefining excellence in 360-Degree Branding, CRM Solutions, and Digital Marketing. Craft legends with Sahara Sculptors."/>
  <meta property="og:image" content="URL to your image"/>
  {/* Add other meta tags as needed */}
</Helmet>
      <AppBar />
      <div className="content1">
  <div className="centered-column">
    <div className=" heading-pricing">
      Your Website is Your Online Identity - Make it Count!
    </div>
    <div className=" sub-heading-pricing">
      At SaharaSculptors, we specialize in crafting bespoke websites that not only look stunning but also drive results. Whether you're a small business looking to establish your online presence or a large corporation in need of a custom web solution, we've got the expertise to bring your vision to life. Our comprehensive suite of web development services covers everything from responsive design and e-commerce integration to SEO optimization and ongoing maintenance.
    </div>
    <div className=" heading-pricing2">
      Website Development Solutions <br />
      Simple, Transparent Pricing
    </div>
    <Pricing2/>
    <Pricing3/>
  </div>
</div>


      {ContainerRow2(<div className="billboard">   <img src={boardw} alt="billborad.png" className="billboard" /></div>)};
      <MyComponent></MyComponent>
    </>
  );
}
export default Website