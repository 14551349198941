import React from "react";
import '../../tabs/shortcodes.css'; // Import the CSS for styling
import Premium from '../../tabs/premium';
import Comprehensive from '../../tabs/comprehensive';
import BudgetFriendly from '../../tabs/budgetfriendly';
function Pricing3() {
  return (
    <section className="pricing-section-three">
      <div className="tf-container st3">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-res">
              <table className="pricing-table">
                <tbody>

<Premium

tittle='Social Media Setup'

/>
<Premium

tittle='5-7 posts per week'

/>
<Premium

tittle='12+ social platform'

/>
<Premium

tittle='Scheduled Communication and report'

/>

<Premium

tittle='Email Support'

/>
<Comprehensive

title='Sculpted Ads Campaign'

/>
<Comprehensive

title='7-14 posts per week'

/><Comprehensive

title='Competitor Analysis'

/>
<Comprehensive

title='Social Media WorkShop'

/>
<BudgetFriendly

title='Premium Support 24/7'

/><BudgetFriendly

title='Advanced Ad Campaigns'

/>
<BudgetFriendly

title='Daily Posts(picture/videos)'

/>
<BudgetFriendly

title='Influencer partnership'

/>



                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing3;
