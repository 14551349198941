import React from "react";
import '../../tabs/shortcodes.css'; // Import the CSS for styling
import Premium from '../../tabs/premium';
import Comprehensive from '../../tabs/comprehensive';
import BudgetFriendly from '../../tabs/budgetfriendly';
function Pricing3() {
  return (
    <section className="pricing-section-three">
      <div className="tf-container st3">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-res">
              <table className="pricing-table">
                <tbody>

<Premium

tittle='Android Basic'

/>
<Premium

tittle='2- 3 core features'

/>
<Premium

tittle='Basic Login & Testing'

/>
<Premium

tittle='App Stores & Sahara App Store'

/>
<Comprehensive

title='Android & IOS Custom UI/UX'

/>
<Comprehensive

title='5- 7 core features'

/><Comprehensive

title='Advanced Testing & Secure Auth'

/>
<Comprehensive

title='External APIs & Custom DataBase'

/>
<BudgetFriendly

title='Cross-platform(Android & IOS)'

/><BudgetFriendly

title='Custom Backend Development'

/>
<BudgetFriendly

title='Desktop Admin'

/>
<BudgetFriendly

title='Premium Support 24/7'

/>


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing3;
