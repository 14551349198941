import React from "react";
import '../../tabs/shortcodes.css'; // Import the CSS for styling
import Premium from '../../tabs/premium';
import Comprehensive from '../../tabs/comprehensive';
import BudgetFriendly from '../../tabs/budgetfriendly';
function Pricing3() {
  return (
    <section className="pricing-section-three">
      <div className="tf-container st3">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-res">
              <table className="pricing-table">
                <tbody>

<Premium

tittle='Logo Design'


/>
<Premium

tittle='Brand Guideline Creation '

/>
<Premium

tittle='Brand Positioning'

/>
<Premium

tittle='Brand Collateral Design'

/>

<Comprehensive

title='Tagline Development'

/>
<Comprehensive

title='Printed Merchandise Design'

/>
<Comprehensive

title='Copelling Brand Story Telling'

/><BudgetFriendly

title='Basic SEO '

/><BudgetFriendly

title='Website Design & Development '

/><BudgetFriendly

title='Basic CRM implimentationa'

/><BudgetFriendly

title='All Comprehensive Services'

/>


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing3;
