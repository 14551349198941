import React from "react";
import '../../tabs/shortcodes.css'; // Import the CSS for styling
import Premium from '../../tabs/premium';
import Comprehensive from '../../tabs/comprehensive';
import BudgetFriendly from '../../tabs/budgetfriendly';
function Pricing3() {
  return (
    <section className="pricing-section-three">
      <div className="tf-container st3">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-res">
              <table className="pricing-table">
                <tbody>

<Premium

tittle='Basic Keyword analysis'

/>
<Premium

tittle='Basic optimization of meta tags'

/>
<Premium

tittle='Market Research and Analysis'

/>
<Premium

tittle='Basic Google My Business Setup'

/>
<Comprehensive

title='Basic Website Edit'

/>
<Comprehensive

title='Competitor Keywords Analysis'

/><Comprehensive

title='Optimizing all Website Pages'

/>
<BudgetFriendly

title='geo targeted solutions'

/>
<BudgetFriendly

title='Site Speed & Mobile Friendliness'

/><BudgetFriendly

title='long-tail keywords'

/>
<BudgetFriendly

title='Advanced Onpage SEO'

/>



                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing3;
