import * as React from "react";
import "./grid.css";

function MyComponent55() {
  const yourServiceData1 = {
    colors: "#FFF9F0", // Replace with your color
    icons:
      "https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/tailored.png?alt=media&token=e8d20317-4ad6-478f-a5b0-d412fa848a98",
  };
  const yourServiceData2 = {
    colors: "#FFFFFF", // Replace with your color
    icons:
      "https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/360.png?alt=media&token=380d28d4-bcd0-4d8d-bc25-93d6205f1d6e",
  };
  const yourServiceData3 = {
    colors: "#FEF7FF", // Replace with your color
    icons:
      "https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/consult.png?alt=media&token=c2e61c6e-f6ce-446e-ab21-2ea93641ecf1",
  };
  const yourServiceData4 = {
    colors: "#FFFFFF", // Replace with your color
    icons:
      "https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/adapti.png?alt=media&token=4759bba4-fd8b-49fd-8844-35e61998d558",
  };
  const yourServiceData5 = {
    colors: "#E6FFF4", // Replace with your color
    icons:
      "https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/dATA.png?alt=media&token=da9cc2ae-febf-4313-be1f-36f2facd19ca",
  };
  const yourServiceData6 = {
    colors: "#FFFFFF", // Replace with your color
    icons:
      "https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/INNO.png?alt=media&token=1e70ec76-a651-4277-a022-78fda66cd6db",
  };
  const yourServiceData7 = {
    colors: "#FFFBE7", // Replace with your color
    icons:
      "https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/CLIENT.png?alt=media&token=44018c66-ff79-4954-8f48-7ee7996c8f78",
  };
  const yourServiceData8 = {
    colors: "#FFFFFF", // Replace with your color
    icons:
      "https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/cross.png?alt=media&token=a3730a6f-cd90-4fa0-94fc-53540d685796",
  };
  const yourServiceData9 = {
    colors: "#FFE3FE", // Replace with your color
    icons:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&",
  };
  const yourServiceData10 = {
    colors: "#FFFFFF", // Replace with your color
    icons:
      "https://firebasestorage.googleapis.com/v0/b/saharasculptors-web.appspot.com/o/SUST.png?alt=media&token=3d97582e-cbae-4050-a590-cac9e38141f4",
  };
  const yourServiceData11 = {
    colors: "#FFE4E4", // Replace with your color
    icons:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&",
  };
  const yourServiceData12 = {
    colors: "#FFFFFF", // Replace with your color
    icons:
      "https://cdn.builder.io/a.pi/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&",
  };
  return (
    <>
      <div className="bxex2">
        {" "}
        {newFunction1(yourServiceData1)}
        {newFunction2(yourServiceData2)}
        {newFunction3(yourServiceData3)}
        {newFunction4(yourServiceData4)}
      </div>
      <div className="bxex2">
        {" "}
        {newFunction5(yourServiceData5)}
        {newFunction6(yourServiceData6)}
        {newFunction7(yourServiceData7)}
        {newFunction8(yourServiceData8)}
      </div>
      <div className="bxex2">
        {" "}
        {newFunction9(yourServiceData9)}
        {newFunction10(yourServiceData10)}
        {newFunction11(yourServiceData11)}
        {newFunction12(yourServiceData12)}
      </div>
     
    </>
  );



  function newFunction1(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Tailored Transformation</div>
                <div className="div-25">
                  <ul>
                    <li>Brand Assessment and Analysis</li>
                    <li>Customized Brand Strategy<br/> Development</li>
                    <li>Target Audience Identification <br/>and Profiling</li>
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }  
  function newFunction2(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">360-Degree Branding</div>
                <div className="div-25">
                  <ul>
                    <li>Logo Design and Visual Identity<br/> Development</li>
                    <li>Website Design and Development</li>
                    <li>Social Media Management and<br/> Content Strategy</li>
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }
  
  function newFunction3(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Consultative Approach</div>
                <div className="div-25">
                  <ul>
                    <li>Extensive Client Interviews and Workshops</li>
                    <li>Client Involvement in the<br/> Creative Process</li>
                    <li>Regular Progress Meetings <br/>and Updates</li>
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }
 
  function newFunction4(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Adaptability </div>
                <div className="div-25">
                  <ul>
                    <li>Ongoing Brand Strategy Evaluation <br/>and Adjustment</li>
                    <li>Monitoring Market and Industry Trends</li>
                    <li>Long-Term Partnership for <br/>Evolving Brand Needs</li>
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }

  function newFunction5(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Data-Driven Insights</div>
                <div className="div-25">
                  <ul>
                    <li>Data Analytics and  Tracking</li>
                    <li>ROI Analysis and Optimization  <br/>Recommendations</li>
                    <li>Customer Feedback Analysis  <br/>and Integration</li>
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }
  function newFunction6(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Innovation</div>
                <div className="div-25">
                  <ul>
                    <li>Trend Analysis and Trendsetting <br/>in Design and Messaging</li>
                    <li>Exploring Emerging Platforms</li>
                    <li>Prototyping and Testing of <br/>Innovative Branding Concepts</li>
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }
  function newFunction7(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Client Education</div>
                <div className="div-25">
                  <ul>
                    <li>Branding Workshops and Seminars</li>
                    <li>Educational Resources <br/>(E-books, Webinars, Guides)</li>
                    <li>Personalized Consultations <br/>and Coaching</li>
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }
 
  function newFunction8(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Cross-Industry Expertise</div>
                <div className="div-25">
                  <ul>
                    <li>industry-Specific Research and Insights</li>
                    <li>Customized Strategies Based<br/>  on Industry Nuances</li>
                    <li>Fresh Design and Messaging<br/> Perspectives</li>
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }
 
  function newFunction9(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Storytelling</div>
                <div className="div-25">
                  <ul>
                    <li>Crafting Brand Narratives and <br/>Storytelling Guidelines</li>
                    <li>Story-Driven Marketing Campaigns</li>
                    <li>Emotional Brand Connections and <br/>RelatabilityTarget Audience Identification and Profiling</li>
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }

  function newFunction10(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://console.firebase.google.com/project/saharasculptors-web/storage/saharasculptors-web.appspot.com/files";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Sustainability and Purpose</div>
                <div className="div-25">
                  <ul>
                    <li>Ethical Branding Practices          </li>
                    <li>Social Responsibility Initiatives</li>
                    <li>Communicating a Brand's Purpose and Impacteaks,<br/> tile replacement, roof cleaning and <br/>maintenance</li>
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }

  function newFunction11(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Architecture</div>
                <div className="div-25">
                  <ul>
                <li>Focuses on creating commercials or ads</li>
                <li>Emphasizes videos that build and promote a brand.</li>
                <li>overs the broader spectrum of video content</li>
                   
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }

  function newFunction12(serviceData) {
    if (serviceData && serviceData.colors) {
      const { colors, icons } = serviceData;

      const iconUrl = icons
        ? icons
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/0f597ac2-17b7-40a8-b1be-b8de8b82fbae?apiKey=f1de14adacde4a0c890766631afd8b1e&";

      return (
        <div className="grid">
          <div className="service" style={{ backgroundColor: colors }}>
            <div className="div-21">
              <div className="div-22">
                <img src={iconUrl} alt="icon" />
              </div>
              <div className="div-23">
                <div className="div-24">Local to Global Scaling 
</div>
                <div className="div-25">
                  <ul>
                    <li>Optimize global logistics for efficiency.</li>
                    <li>Assess global opportunities and competition.</li>
                    <li>Execute precise global marketing campaigns.</li>
                  
                  </ul>
                </div>
              </div>
              <div className="div-26">
                <div className="div-27">
                  <div className="div-28">More Info</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Return null or handle the case where serviceData or colors are not present
      return null;
    }
  }


}

export default MyComponent55;
